import React, { useEffect } from "react"
import { Trans } from "react-i18next"
import { Spinner } from "react-bootstrap"
import Layout from "../components/template/Layout"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AppRoutes } from "../utilities/AppRoutes"

const PaymentStatus = () => {
    // States and Hooks
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const success = JSON.parse(searchParams.get("success").toLocaleLowerCase())

    // Methods
    const handleRedirect = () => {
        if (success) return navigate(AppRoutes.paymentSuccess)
        return navigate(AppRoutes.paymentFailure)
    }

    // UseEffects
    useEffect(() => {
        handleRedirect()
    }, [])

    // Render
    return (
        <Layout fullWidth>
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="d-flex gap-2 justify-content-center align-items-center my-5">
                    <div>
                        <Spinner animation="border" className="border-2" />
                    </div>
                    <div>
                        <Trans>Processing Payment...</Trans>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PaymentStatus
