import React, { forwardRef } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import tr from "date-fns/locale/tr"
import { useApp } from "../../providers/AppProvider"

registerLocale("tr", tr)

const DesktopDateTimePickerField = ({
    icon,
    dateTime,
    setDateTime,
    placeholder,
    minDate,
    maxDate,
    disabled = false,
    unavailableDates = [],
}) => {
    // States and Hooks
    const { currentLanguage } = useApp()

    // Render
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={`d-flex align-items-center cursor-pointer ${disabled && "opacity-50"}`}
            onClick={onClick}
        >
            <div className="fs-14px fw-800 text-nowrap ps-3">{placeholder}</div>
            <input
                type="text"
                className={`w-100 border-0 form-control ${
                    value ? "py-2px" : "py-0"
                } px-1 fs-14px fw-600 cursor-pointer`}
                ref={ref}
                value={value}
            />
            {!value && (
                <div className="pr-10px">
                    <img src={icon} alt="date" />
                </div>
            )}
        </div>
    ))

    return (
        <DatePicker
            locale={currentLanguage}
            timeCaption={currentLanguage === "en" ? "Time" : "Zaman"}
            onChange={setDateTime}
            selected={dateTime}
            dropdownMode="select"
            placeholderText="Select start date and time"
            dateFormat="dd/MM/yyyy HH:mm"
            shouldCloseOnSelect
            showMonthDropdown
            showYearDropdown
            isClearable={dateTime}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={5}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomInput />}
            disabled={disabled}
            excludeDates={unavailableDates}
        />
    )
}

export default DesktopDateTimePickerField
