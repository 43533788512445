import React, { useEffect, useRef } from "react"
import { useCompleteInformationForm } from "../../providers/complete-information/CompleteInformationFormProvider"

const HiddenOriginalPaymentForm = () => {
    // States and Hooks
    const { completeInformationData, cardNumber, cardExpireDate, cardCvv } =
        useCompleteInformationForm()
    const paymentButtonRef = useRef()
    const cardnumber = cardNumber.split(" ").join("")
    const cardexpiredatemonth = cardExpireDate.split("/")[0]
    const cardexpiredateyear = cardExpireDate.split("/")[1]
    const cardcvv2 = cardCvv

    // UseEffects
    useEffect(() => {
        if (completeInformationData) paymentButtonRef.current.click()
    }, [completeInformationData])

    // Render
    return (
        <form
            action="https://sanalposprov.garanti.com.tr/servlet/gt3dengine"
            method="POST"
            className="d-none"
        >
            <label for="cardnumber">cardnumber:</label>
            <br />
            <input id="cardnumber" name="cardnumber" value={cardnumber} />
            <br />

            <label for="cardexpiredatemonth">Expire Date (mm):</label>
            <br />
            <input
                id="cardexpiredatemonth"
                name="cardexpiredatemonth"
                value={cardexpiredatemonth}
            />
            <br />

            <label for="cardexpiredateyear">Expire Date (yy):</label>
            <br />
            <input id="cardexpiredateyear" name="cardexpiredateyear" value={cardexpiredateyear} />
            <br />

            <label for="cardcvv2">CVV2:</label>
            <br />
            <input id="cardcvv2" name="cardcvv2" value={cardcvv2} />
            <br />

            <label for="mode">Mode:</label>
            <br />
            <input id="mode" name="mode" value={completeInformationData?.mode} />
            <br />

            <label for="secure3dsecuritylevel">secure3dsecuritylevel:</label>
            <br />
            <input
                id="secure3dsecuritylevel"
                name="secure3dsecuritylevel"
                value={completeInformationData?.secure3dsecuritylevel}
            />
            <br />

            <label for="apiversion">apiversion:</label>
            <br />
            <input id="apiversion" name="apiversion" value={completeInformationData?.apiversion} />
            <br />

            <label for="terminalprovuserid">terminalprovuserid:</label>
            <br />
            <input
                id="terminalprovuserid"
                name="terminalprovuserid"
                value={completeInformationData?.terminalprovuserid}
            />
            <br />

            <label for="terminaluserid">terminaluserid:</label>
            <br />
            <input
                id="terminaluserid"
                name="terminaluserid"
                value={completeInformationData?.terminaluserid}
            />
            <br />

            <label for="terminalmerchantid">terminalmerchantid:</label>
            <br />
            <input
                id="terminalmerchantid"
                name="terminalmerchantid"
                value={completeInformationData?.terminalmerchantid}
            />
            <br />

            <label for="txntype">txntype:</label>
            <br />
            <input id="txntype" name="txntype" value={completeInformationData?.txntype} />
            <br />

            <label for="txnamount">txnamount:</label>
            <br />
            <input id="txnamount" name="txnamount" value={completeInformationData?.txnamount} />
            <br />

            <label for="txncurrencycode">txncurrencycode:</label>
            <br />
            <input
                id="txncurrencycode"
                name="txncurrencycode"
                value={completeInformationData?.txncurrencycode}
            />
            <br />

            <label for="txninstallmentcount">txninstallmentcount:</label>
            <br />
            <input
                id="txninstallmentcount"
                name="txninstallmentcount"
                value={completeInformationData?.txninstallmentcount}
            />
            <br />

            <label for="orderid">orderid:</label>
            <br />
            <input id="orderid" name="orderid" value={completeInformationData?.orderid} />
            <br />

            <label for="terminalid">terminalid:</label>
            <br />
            <input id="terminalid" name="terminalid" value={completeInformationData?.terminalid} />
            <br />

            <label for="successurl">successurl:</label>
            <br />
            <input id="successurl" name="successurl" value={completeInformationData?.successurl} />
            <br />

            <label for="errorurl">errorurl:</label>
            <br />
            <input id="errorurl" name="errorurl" value={completeInformationData?.errorurl} />
            <br />

            <label for="customeremailaddress">customeremailaddress:</label>
            <br />
            <input
                id="customeremailaddress"
                name="customeremailaddress"
                value={completeInformationData?.customeremailaddress}
            />
            <br />

            <label for="customeripaddress">customeripaddress:</label>
            <br />
            <input
                id="customeripaddress"
                name="customeripaddress"
                value={completeInformationData?.customeripaddress}
            />
            <br />

            <label for="secure3dhash">secure3dhash:</label>
            <br />
            <input
                id="secure3dhash"
                name="secure3dhash"
                value={completeInformationData?.secure3dhash}
            />
            <br />
            <button ref={paymentButtonRef} type="submit">
                PAY
            </button>
            <br />
        </form>
    )
}

export default HiddenOriginalPaymentForm
