import React from "react"
import { TickCircleActive, Calendar } from "../../utilities/ImageImports"
import { Trans } from "react-i18next"
const CarSelect = ({
    imgName,
    carTitle,
    capacity,
    date,
    active,
    selectHandler,
    currencyType,
    price,
}) => {
    return (
        <button
            className={`w-100 bg-white border-1 p-0 m-0 hover-border-gray ${
                active ? "border-dark" : "border"
            }`}
            onClick={selectHandler}
        >
            <div className="row m-0 p-3 align-items-md-center">
                <div className="col-12 pb-2 pb-sm-0 col-sm-7 col-md-6 p-0">
                    <div className="row align-items-md-center m-0 flex-fill">
                        <div className="col-12 col-sm-4 border p-1 py-3 mb-3 mb-sm-0 position-relative">
                            <img src={imgName} alt={carTitle} className="img-fluid" />
                            {active && (
                                <img
                                    src={TickCircleActive}
                                    alt="tick-circle"
                                    className="position-absolute bottom-0 start-0"
                                />
                            )}
                        </div>
                        <div className="col-12 col-sm-8 p-0 ps-2">
                            <div className="d-flex align-items-center flex-md-column align-items-md-start gap-2 gap-md-0">
                                <h6 className="fs-18px fw-500 m-0 mb-0 text-sm-start">
                                    {carTitle}
                                </h6>
                                <p className="fs-12px mb-0 mb-md-2  text-sm-start">
                                    <Trans>Capacity</Trans>: {capacity} <Trans>people</Trans>
                                </p>
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-2 mt-2 mt-md-0">
                                <img src={Calendar} alt="calendar" className="img-fluid" />
                                <p className="fs-14px fw-normal m-0">{date}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-5 col-md-6 pb-2 pb-sm-0 fw-bold">
                    <div className="d-flex w-100 align-items-center gap-2 justify-content-md-between">
                        <div>dk</div>
                        <div>
                            <span>{price}</span>
                            <span>{currencyType}</span>
                        </div>
                        <button
                            className={`btn btn-secondary ${
                                active && "invisible d-none d-md-block"
                            }`}
                        >
                            <Trans>Pick</Trans>
                        </button>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default CarSelect
