import React, { useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Icons } from "../../utilities/Icons"
import { AppRoutes } from "../../utilities/AppRoutes"
import { Logo, BgPaymentFail } from "../../utilities/ImageImports"
import Loader from "../template/Loader"
import { useApi } from "../../providers/ApiProvider"
import { useApp, TripTypes } from "../../providers/AppProvider"

const PaymentFail = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { reservations } = useApi()
    const {
        setSessionId,
        setFromValue,
        setWhereValue,
        setWhenValue,
        setReturnValue,
        setPeopleValue,
        setCurrentTripType,
        setFirstWayVehicleType,
        setSecondWayVehicleType,
    } = useApp()
    const [isBackToHomeClicked, setIsBackToHomeClicked] = useState(false)
    const [isRetryClicked, setIsRetryClicked] = useState(false)
    const isLocalStorageValid =
        localStorage.getItem("prevSearchObject") &&
        localStorage.getItem("appObject") &&
        localStorage.getItem("prevReservationData")

    // Methods
    const prefillCompleteInformationForm = async () => {
        const { data: searchResult } = await reservations.search(
            JSON.parse(localStorage.getItem("prevSearchObject"))
        )

        const appObject = JSON.parse(localStorage.getItem("appObject"))
        const prevReservationData = JSON.parse(localStorage.getItem("prevReservationData"))

        setSessionId(searchResult.sessionId)
        setFromValue(appObject.fromValue)
        setWhereValue(appObject.whereValue)
        setWhenValue(appObject.whenValue)
        setPeopleValue(appObject.peopleValue)
        setCurrentTripType(appObject.currentTripType)
        setFirstWayVehicleType(
            searchResult.firstWay.vehicleOptions.filter(
                vehicleOption =>
                    vehicleOption.vehicleTypeId === prevReservationData.firstWay.vehicleTypeId
            )[0]
        )

        if (appObject.currentTripType === TripTypes.RoundTrip) {
            setReturnValue(appObject.returnValue)
            setSecondWayVehicleType(
                searchResult.secondWay.vehicleOptions.filter(
                    vehicleOption =>
                        vehicleOption.vehicleTypeId === prevReservationData.secondWay.vehicleTypeId
                )[0]
            )
        }
    }

    // Render
    return (
        <>
            <div className="row m-0 justify-content-center px-3 px-sm-0">
                <div className="col-12 col-sm-8 col-lg-6 bg-white p-0 payment-card">
                    <div
                        className="text-center payment-card-title position-relative py-4"
                        style={{
                            backgroundImage: `url(${BgPaymentFail})`,
                            backgroundPosition: "0px -75px",
                        }}
                    >
                        <div className="col-6 col-sm-4 mx-auto">
                            <img src={Logo} alt="logo" className="img-fluid py-4" />
                        </div>
                        <div className="payment-status-icon">{Icons.failCloseCircle()}</div>
                    </div>

                    <div className="h4 m-0 fw-600 text-danger text-center mb-5">
                        <Trans>Payment Failed</Trans>
                    </div>
                    <div className="d-flex flex-column payment-card-description gap-2 pb-5">
                        {isLocalStorageValid && (
                            <button
                                disabled={isRetryClicked}
                                onClick={async () => {
                                    setIsRetryClicked(true)
                                    await prefillCompleteInformationForm()
                                    navigate(AppRoutes.completeInformation)
                                }}
                                className="btn btn-primary rounded-0 fw-600 py-3"
                            >
                                {isRetryClicked ? <Loader dark /> : <Trans>Retry</Trans>}
                            </button>
                        )}
                        <button
                            disabled={isBackToHomeClicked}
                            onClick={() => {
                                setIsBackToHomeClicked(true)
                                window?.ReactNativeWebView?.postMessage("BackToHomeScreen")
                                setTimeout(() => navigate(AppRoutes.home), 2000)
                            }}
                            className="btn btn-secondary rounded-0 fw-500 py-3"
                        >
                            {isBackToHomeClicked ? <Loader /> : <Trans>Back to Home Page</Trans>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentFail
