import { useTrack } from "../../providers/TrackProvider"
import React, { useEffect, useRef, useState } from "react"
import { GoogleMap, DirectionsRenderer, Marker } from "@react-google-maps/api"

const TrackingMap = () => {
    // States and Hooks
    const { detailData, trackData, trackReservation } = useTrack()
    const timer = useRef()
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [carPosition] = useState(trackData)

    // Methods
    const calculateRoute = async () => {
        const directionsService = new window.google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: detailData?.origin?.name,
            destination: detailData?.destination?.name,
            travelMode: window.google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
    }

    // UseEffects
    useEffect(() => {
        calculateRoute()
    }, [])

    useEffect(() => {
        timer.current = setInterval(trackReservation, 5_000)
        return () => clearInterval(timer.current)
    }, [timer.current])

    // Render
    return (
        <GoogleMap
            zoom={1}
            mapContainerClassName="map-item"
            options={{
                zoomControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
            }}
            center={{
                lat: carPosition?.latitude,
                lng: carPosition?.longitude,
            }}
        >
            {directionsResponse && (
                <Marker
                    icon={{
                        url:
                            detailData?.vehicleType?.name === "VITO"
                                ? "https://test.transferix.net/img/2091dedf-6c44-473f-8c15-ec985b21eb0b.png"
                                : "https://test.transferix.net/img/116556fc79693490de2d8a476a79f4ee.png",

                        scaledSize: new window.google.maps.Size(
                            100,
                            detailData?.vehicleType?.name === "VITO" ? 65 : 55
                        ),
                    }}
                    position={{
                        lat: carPosition?.latitude,
                        lng: carPosition?.longitude,
                    }}
                />
            )}
            {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
        </GoogleMap>
    )
}

export default TrackingMap
