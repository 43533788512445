import React from "react"
import { BrowserRouter } from "react-router-dom"
import Home from "./pages/Home"
import CompleteInformation from "./pages/CompleteInformation"
import { Routes, Route } from "react-router"
import { AppRoutes } from "./utilities/AppRoutes"
import Contact from "./pages/Contact"
import Services from "./pages/Services"
import AboutUs from "./pages/AboutUs"
import History from "./pages/History"
import Search from "./pages/Search"
import NotFound from "./pages/NotFound"
import AppProvider from "./providers/AppProvider"
import { withTranslation } from "react-i18next"
import SearchProvider from "./providers/SearchProvider"
import ContactFormProvider from "./providers/ContactFormProvider"
import OrderTracking from "./pages/OrderTracking"
import HomeProvider from "./providers/home/HomeProvider"
import ApiProvider from "./providers/ApiProvider"

import PaymentSuccessPage from "./pages/PaymentSuccess"
import PaymentFailurePage from "./pages/PaymentFailure"
import PaymentStatus from "./pages/PaymentStatus"
import PaymentMobile from "./pages/PaymentMobile"
import { TrackProvider } from "./providers/TrackProvider"
import Feedback from "./pages/Feedback"
import { FeedbackProvider } from "./providers/FeedbackProvider"

function App() {
    return (
        <ApiProvider>
            <BrowserRouter>
                <AppProvider>
                    <Routes>
                        <Route
                            path={AppRoutes.home}
                            element={
                                <HomeProvider>
                                    <Home />
                                </HomeProvider>
                            }
                        />
                        <Route
                            path={AppRoutes.completeInformation}
                            element={<CompleteInformation />}
                        />
                        <Route
                            path={AppRoutes.contact}
                            element={
                                <ContactFormProvider>
                                    <Contact />
                                </ContactFormProvider>
                            }
                        />
                        <Route path={AppRoutes.services} element={<Services />} />
                        <Route path={AppRoutes.aboutUs} element={<AboutUs />} />
                        <Route path={AppRoutes.history} element={<History />} />
                        {/* Payments */}
                        <Route path={AppRoutes.paymentStatus} element={<PaymentStatus />} />
                        <Route path={AppRoutes.paymentSuccess} element={<PaymentSuccessPage />} />
                        <Route path={AppRoutes.paymentFailure} element={<PaymentFailurePage />} />
                        <Route path={AppRoutes.paymentMobile} element={<PaymentMobile />} />

                        <Route
                            path={AppRoutes.search}
                            element={
                                <SearchProvider>
                                    <Search />
                                </SearchProvider>
                            }
                        />
                        <Route
                            path={AppRoutes.orderTracking + "/:code"}
                            element={
                                <TrackProvider>
                                    <OrderTracking />
                                </TrackProvider>
                            }
                        />
                        <Route
                            path={AppRoutes.feedback + "/:code"}
                            element={
                                <FeedbackProvider>
                                    <Feedback />
                                </FeedbackProvider>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </AppProvider>
            </BrowserRouter>
        </ApiProvider>
    )
}

export default withTranslation()(App)
