import React, { useState, createContext, useContext, useEffect } from "react"
import {
    OneWayCompleteInformationFormSchema,
    PaymentFormSchema,
} from "../../utilities/FormValidations"
import { RoundTripCompleteInformationFormSchema } from "../../utilities/FormValidations"
import { useNavigate } from "react-router"
import { AppRoutes } from "../../utilities/AppRoutes"
import { scrollIntoViewById } from "../../functions/scrollIntoViewById"
import { TripTypes } from "../AppProvider"
import { useApp } from "../AppProvider"
import { useApi } from "../ApiProvider"

const CompleteInformationFormContext = createContext()
export const useCompleteInformationForm = () => useContext(CompleteInformationFormContext)

const CompleteInformationFormProvider = ({ children }) => {
    // States and Hooks
    const {
        currentTripType,
        sessionId,
        firstWayVehicleType,
        secondWayVehicleType,
        currentLanguage,
        fromValue,
        whereValue,
    } = useApp()

    const { reservations, extras } = useApi()

    const prevReservationData = JSON.parse(localStorage.getItem("prevReservationData"))
    const prevPaymentData = JSON.parse(localStorage.getItem("prevPaymentData"))

    const [firstname, setFirstname] = useState(prevReservationData?.firstName || "")
    const [lastname, setLastname] = useState(prevReservationData?.lastName || "")
    const [email, setEmail] = useState(prevReservationData?.email || "")
    const [phoneNumber, setPhoneNumber] = useState(prevReservationData?.phoneNumber || "")
    const [note, setNote] = useState(prevReservationData?.firstWay.customerNote || "")
    const [firstWayOriginDetails, setFirstWayOriginDetails] = useState(
        prevReservationData?.firstWay?.originAddressDetail || fromValue
    )
    const [firstWayDestinationDetails, setFirstWayDestinationDetails] = useState(
        prevReservationData?.firstWay?.destinationAddressDetail || whereValue
    )
    const [firstWayFlightNumber, setFirstWayFlightNumber] = useState(
        prevReservationData?.firstWay?.flightNumber || ""
    )
    const [firstWayExtraItems, setFirstWayExtraItems] = useState(
        prevReservationData?.firstWay?.extraItems?.length > 0
            ? prevReservationData.firstWay.extraItems
            : []
    )
    const [secondWayOriginDetails, setSecondWayOriginDetails] = useState(
        prevReservationData?.secondWay?.originAddressDetail || whereValue
    )
    const [secondWayDestinationDetails, setSecondWayDestinationDetails] = useState(
        prevReservationData?.secondWay?.destinationAddressDetail || fromValue
    )
    const [secondWayFlightNumber, setSecondWayFlightNumber] = useState(
        prevReservationData?.secondWay?.flightNumber || ""
    )
    const [secondWayExtraItems, setSecondWayExtraItems] = useState(
        prevReservationData?.secondWay?.extraItems?.length > 0
            ? prevReservationData.secondWay.extraItems
            : []
    )
    const [extraItems, setExtraItems] = useState(null)
    const [extraItemsLoading, setExtraItemsLoading] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)

    const [completeInformationData, setCompleteInformationData] = useState(null)

    const [cardNumber, setCardNumber] = useState(prevPaymentData?.cardNumber || "")
    const [cardExpireDate, setCardExpireDate] = useState(prevPaymentData?.cardExpireDate || "")
    const [cardCvv, setCardCvv] = useState("")

    const navigate = useNavigate()
    const [error, setError] = useState("")
    const [paymentError, setPaymentError] = useState("")
    const [submitLoading, setSubmitLoading] = useState(false)

    const [directionsResponse, setDirectionsResponse] = useState(null)

    const getValidationSchema = () => {
        return currentTripType === TripTypes.RoundTrip
            ? RoundTripCompleteInformationFormSchema
            : OneWayCompleteInformationFormSchema
    }

    const payment = async () => {
        setSubmitLoading(true)

        const firstWayExtraItemsIds = firstWayExtraItems.map(item => item.id)
        const secondWayExtraItemsIds = secondWayExtraItems.map(item => item.id)

        const reservationCreateObject = {
            sessionId,
            languageId: currentLanguage === "tr" ? 1 : 2,
            firstName: firstname,
            lastName: lastname,
            email,
            phoneNumber,
            firstWay: {
                vehicleTypeId: firstWayVehicleType.vehicleTypeId,
                flightNumber: firstWayFlightNumber,
                customerNote: note,
                originAddressDetail: firstWayOriginDetails,
                destinationAddressDetail: firstWayDestinationDetails,
                extraIds: firstWayExtraItemsIds,
            },
            ...(currentTripType === TripTypes.RoundTrip && {
                secondWay: {
                    vehicleTypeId: secondWayVehicleType.vehicleTypeId,
                    flightNumber: secondWayFlightNumber,
                    customerNote: note,
                    originAddressDetail: secondWayOriginDetails,
                    destinationAddressDetail: secondWayDestinationDetails,
                    extraIds: secondWayExtraItemsIds,
                },
            }),
        }

        const { data, error } = await reservations.create(reservationCreateObject)
        if (error) {
            setError("Something went wrong")
            navigate(AppRoutes.paymentFailure)
        }
        if (data !== null) {
            localStorage.setItem(
                "prevReservationData",
                JSON.stringify({
                    ...reservationCreateObject,
                    firstWay: {
                        ...reservationCreateObject.firstWay,
                        extraItems: firstWayExtraItems,
                    },
                    secondWay: {
                        ...reservationCreateObject.secondWay,
                        extraItems: secondWayExtraItems,
                    },
                })
            )
            setCompleteInformationData(data)
        }

        setSubmitLoading(false)
    }

    const onPayButtonClick = async e => {
        e.preventDefault()
        setError("")
        setPaymentError("")
        scrollIntoViewById("complete-information-form")

        const validationObject = {
            firstname,
            lastname,
            email,
            phoneNumber,
            note,
            firstWayOriginDetails,
            firstWayDestinationDetails,
            firstWayFlightNumber,
        }

        if (currentTripType === TripTypes.RoundTrip) {
            validationObject.secondWayOriginDetails = secondWayOriginDetails
            validationObject.secondWayDestinationDetails = secondWayDestinationDetails
            validationObject.secondWayFlightNumber = secondWayFlightNumber
        }

        const validationResponse = await getValidationSchema()
            .validate(validationObject)
            .catch(error => setError(error.errors))

        if (validationResponse) {
            const paymentValidationResponse = await PaymentFormSchema.validate({
                cardNumber: cardNumber.split(" ").join(""),
                cardExpireDate,
                cardCvv,
            }).catch(error => setPaymentError(error.errors))

            if (paymentValidationResponse) {
                const paymentDataObject = { cardNumber, cardExpireDate }
                localStorage.setItem("prevPaymentData", JSON.stringify({ ...paymentDataObject }))
                return payment()
            }
        }
    }

    const fetchExtras = async () => {
        setExtraItemsLoading(true)
        const { data } = await extras.fetch()
        if (data) {
            setExtraItems(
                data
                    .filter(item => item.prices.find(price => price.currencyTypeId === 1))
                    .map(item => ({
                        id: item.id,
                        name: item.localizedNames.find(
                            name => name.language.name === currentLanguage
                        ).localizedName,
                        price: item.prices.find(price => price.currencyTypeId === 1).price,
                    }))
            )
        }
        setExtraItemsLoading(false)
    }

    useEffect(() => {
        fetchExtras()
        let totalPriceValue = firstWayVehicleType?.price
        if (secondWayVehicleType) totalPriceValue = totalPriceValue + secondWayVehicleType?.price
        totalPriceValue = Math.round(totalPriceValue * 10000) / 10000
        setTotalPrice(totalPriceValue)
    }, [])

    useEffect(() => {
        let totalPriceValue = firstWayVehicleType?.price
        if (secondWayVehicleType) totalPriceValue = totalPriceValue + secondWayVehicleType?.price

        const totalFirstWayExtraItemsPriceValue = firstWayExtraItems
            .map(item => item.price)
            .reduce((partialSum, a) => partialSum + a, 0)

        const totalSecondWayExtraItemsPriceValue = secondWayExtraItems
            .map(item => item.price)
            .reduce((partialSum, a) => partialSum + a, 0)

        const totalExtraItemsPriceValue =
            totalFirstWayExtraItemsPriceValue + totalSecondWayExtraItemsPriceValue

        totalPriceValue = totalPriceValue + totalExtraItemsPriceValue

        totalPriceValue = Math.round(totalPriceValue * 10000) / 10000
        setTotalPrice(totalPriceValue)
    }, [firstWayExtraItems, secondWayExtraItems])

    useEffect(() => {
        fetchExtras()
    }, [currentLanguage])

    // Binding
    const valueObject = {
        // States
        error,
        paymentError,
        submitLoading,

        firstname,
        setFirstname,

        lastname,
        setLastname,

        email,
        setEmail,

        phoneNumber,
        setPhoneNumber,

        note,
        setNote,

        firstWayOriginDetails,
        setFirstWayOriginDetails,

        firstWayDestinationDetails,
        setFirstWayDestinationDetails,

        firstWayFlightNumber,
        setFirstWayFlightNumber,

        firstWayExtraItems,
        setFirstWayExtraItems,

        secondWayOriginDetails,
        setSecondWayOriginDetails,

        secondWayDestinationDetails,
        setSecondWayDestinationDetails,

        secondWayFlightNumber,
        setSecondWayFlightNumber,

        secondWayExtraItems,
        setSecondWayExtraItems,

        extraItems,
        setExtraItems,

        extraItemsLoading,
        setExtraItemsLoading,

        totalPrice,
        setTotalPrice,

        cardNumber,
        setCardNumber,

        cardExpireDate,
        setCardExpireDate,

        cardCvv,
        setCardCvv,
        completeInformationData,

        directionsResponse,
        setDirectionsResponse,

        // Methods
        fetchExtras,
        onPayButtonClick,
    }

    // Render
    return (
        <CompleteInformationFormContext.Provider value={valueObject}>
            {children}
        </CompleteInformationFormContext.Provider>
    )
}

export default CompleteInformationFormProvider
