import React from "react"
import "react-day-picker/dist/style.css"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import TextField from "@mui/material/TextField"
import { Trans, useTranslation } from "react-i18next"
import trLocale from "date-fns/locale/tr"
import { trTR } from "@mui/x-date-pickers"

const MobileDateTimePickerField = ({
    placeholder,
    icon,
    minDate,
    maxDate,
    date,
    setDateTime,
    hideIconOnSelected,
    unavailableDates = [],
    disabled = false,
}) => {
    const { i18n } = useTranslation()

    const disableUnavailableDates = date => {
        return unavailableDates.includes(date.toDateString())
    }

    return (
        <div className="d-flex date-picker-container align-items-center h-100 w-100">
            <div className="fs-14px fw-800 ps-3 text-nowrap">
                <Trans>{placeholder}</Trans>
            </div>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={i18n.language === "tr" ? trLocale : ""}
                localeText={
                    i18n.language === "tr"
                        ? trTR.components.MuiLocalizationProvider.defaultProps.localeText
                        : undefined
                }
            >
                <MobileDateTimePicker
                    label={
                        date ? (
                            !hideIconOnSelected && (
                                <div className="d-flex w-100 justify-content-end align-items-center">
                                    <img src={icon} alt="date" />
                                </div>
                            )
                        ) : (
                            <div className="d-flex w-100 justify-content-end align-items-center">
                                <img src={icon} alt="date" />
                            </div>
                        )
                    }
                    value={date}
                    minDateTime={minDate}
                    maxDateTime={maxDate}
                    onChange={newValue => {
                        setDateTime(newValue)
                    }}
                    shouldDisableDate={disableUnavailableDates}
                    renderInput={params => <TextField {...params} />}
                    inputFormat={"dd/MM/yyyy HH:mm"}
                    ampm={false}
                    disabled={disabled}
                />
            </LocalizationProvider>
        </div>
    )
}

export default MobileDateTimePickerField
