import React from "react"
import Layout from "../components/template/Layout"
import Hero from "../components/history/Hero"
import Credit from "../components/history/Credit"
import Tours from "../components/history/Tours"
import NewsLetter from "../components/home/NewsLetter"

const History = () => {
    return (
        <Layout>
            <Hero />
            <Credit />
            <Tours />
            <NewsLetter />
            <Tours />
        </Layout>
    )
}

export default History
