import React from "react"
import Credit from "../components/home/Credit"
import Hero from "../components/home/Hero"
import MiniServices from "../components/home/MiniServices"
import Services from "../components/home/Services"
import TransferPrices from "../components/home/TransferPrices"
import Layout from "../components/template/Layout"

const Home = () => {
    // Render
    return (
        <Layout>
            <div className="bg-gray">
                <Hero />
                <Credit />
                <MiniServices />
                <TransferPrices />
                <Services />
            </div>
        </Layout>
    )
}

export default Home
