import React from "react"
import Layout from "../components/template/Layout"
import FeaturedPage from "../components/template/FeaturedPage"
import PaymentFail from "../components/payment/PaymentFail"

const PaymentFailurePage = () => {
    return (
        <Layout>
            <FeaturedPage>
                <PaymentFail />
            </FeaturedPage>
        </Layout>
    )
}

export default PaymentFailurePage
