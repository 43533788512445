// Dependencies
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import AboutUsPageContent from "./content/AboutUs.json"
import CompleteInformationPageContent from "./content/CompleteInformation.json"
import ContactPageContent from "./content/Contact.json"
import FooterPageContent from "./content/Footer.json"
import HeaderPageContent from "./content/Header.json"
import HeaderOverlayPageContent from "./content/HeaderOverlay.json"
import HistoryPageContent from "./content/History.json"
import HomePageContent from "./content/Home.json"
import NotFoundPageContent from "./content/NotFound.json"
import OrderTrackingContent from "./content/OrderTracking.json"
import PaymentStatusContent from "./content/PaymentStatus.json"
import SearchPageContent from "./content/Search.json"
import ServicesPageContent from "./content/Services.json"
import FormValidationsContent from "./content/FormValidations.json"
import FeedbackContent from "./content/Feedback.json"

i18n.use(initReactI18next).init({
    // Language initialization
    resources: {
        en: {
            translations: {
                ...AboutUsPageContent.en,
                ...CompleteInformationPageContent.en,
                ...ContactPageContent.en,
                ...FooterPageContent.en,
                ...HeaderPageContent.en,
                ...HeaderOverlayPageContent.en,
                ...HistoryPageContent.en,
                ...HomePageContent.en,
                ...NotFoundPageContent.en,
                ...OrderTrackingContent.en,
                ...PaymentStatusContent.en,
                ...SearchPageContent.en,
                ...ServicesPageContent.en,
                ...FormValidationsContent.en,
                ...FeedbackContent.en,
            },
        },
        tr: {
            translations: {
                ...AboutUsPageContent.tr,
                ...CompleteInformationPageContent.tr,
                ...ContactPageContent.tr,
                ...FooterPageContent.tr,
                ...HeaderPageContent.tr,
                ...HeaderOverlayPageContent.tr,
                ...HistoryPageContent.tr,
                ...HomePageContent.tr,
                ...NotFoundPageContent.tr,
                ...OrderTrackingContent.tr,
                ...PaymentStatusContent.tr,
                ...SearchPageContent.tr,
                ...ServicesPageContent.tr,
                ...FormValidationsContent.tr,
                ...FeedbackContent.tr,
            },
        },
    },
    fallbackLng: "tr",
    debug: false, // TODO: Change this to false for production.

    // Have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false,
    },
})

export default i18n
