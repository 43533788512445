import React from "react"
import { HistoryHeroBackground } from "../../utilities/ImageImports"
import { Trans } from "react-i18next"

const Hero = () => {
    return (
        <div
            className="history-hero"
            style={{
                backgroundImage: `url(${HistoryHeroBackground})`,
            }}
        >
            <div className="history-hero-overlay my-auto row m-0 flex-lg-column justify-content-center px-1 px-sm-5 py-3">
                <div className="history-hero-title m-0 text-white fw-500 col-12 col-lg-6 pb-4 py-sm-4 align-self-end align-self-lg-start text-center text-sm-start">
                    <Trans>Transferix, from past to today</Trans>
                </div>
                <div className="h5 m-0 text-white fw-light col-12 col-lg-6 history-hero-description lh-200pr text-center text-sm-start">
                    <Trans>
                        Transferix is a company that has been providing solutions in the of
                        transportation for more than 40 years and has adopted the principle of
                        providing service. Our team works hard to provide all services that
                        companies or individuals need in a reliable, punctual and rational way. Our
                        most important principle is that the services we offer to our customers are
                        transparent and measurable, and with performance-oriented works, we have
                        succeeded in integrating the motto “always with our customers“ into business
                        processes with transparency.
                    </Trans>
                </div>
            </div>
        </div>
    )
}

export default Hero
