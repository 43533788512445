import React from "react"
import { NewsLetterBackground } from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"

const NewsLetter = () => {
    const { t } = useTranslation()
    return (
        <div className="px-4 py-5 p-lg-5 bg-white">
            <div className="p-0 p-lg-5">
                <div
                    className="news-letter py-5"
                    style={{
                        backgroundImage: `url(${NewsLetterBackground})`,
                    }}
                >
                    <div className="col-12 mx-auto h-100">
                        <div className="h-100 row m-0 align-items-center justify-content-center flex-column">
                            <div className="col-11 col-sm-7 mb-3 text-light text-center h1 fw-bold ls-m5p lh-133pr">
                                <Trans>Subscribe to the</Trans>{" "}
                                <span className="text-primary">Transferix</span>{" "}
                                <Trans>Newsletter</Trans>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6">
                                <div className="input-group mt-3">
                                    <input
                                        type="text"
                                        className="input-email form-control form-control-lg rounded-0 col-8 col-sm-9 mb-sm-0 ls-m5p border-0 fw-light"
                                        placeholder={t("Enter Your Email")}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-primary rounded-0 fw-500 col-4 fs-16px col-sm-3 p-1 p-sm-2 ls-m5p"
                                    >
                                        <Trans>Register</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter
