import axios from "axios"

const BaseApiUrl = process.env.REACT_APP_API_BASE_URL

export const axiosRequest = async ({ url, method, body = null, headers = null }) => {
    let output = { error: null, data: null }
    try {
        const response = await axios({
            url: `${BaseApiUrl}${url}`,
            method: method,
            data: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                ...headers,
            },
        })
        if (response)
            return (output = {
                error: null,
                data: response.data,
            })
        return (output = {
            error: "Something went wrong",
            data: null,
        })
    } catch (error) {
        return (output = {
            error: error.message,
            data: null,
        })
    }
}
