import { AppRoutes } from "./AppRoutes"

export const HeaderLinks = [
    {
        label: "Home",
        link: AppRoutes.home,
    },
    {
        label: "About Us",
        link: AppRoutes.aboutUs,
    },
    // {
    //     label: "Services",
    //     link: AppRoutes.services,
    // },
    {
        label: "Contact",
        link: AppRoutes.contact,
    },
]
