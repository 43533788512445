import React, { useContext, createContext } from "react"
import { axiosRequest } from "../api/axiosRequest"

const ApiContext = createContext()
export const useApi = () => useContext(ApiContext)

const ApiProvider = ({ children }) => {
    // States and Hooks
    // Methods
    // Binding

    const valueObject = {
        reservations: {
            search: async ({
                originLat,
                originLon,
                originName,
                destinationLat,
                destinationLon,
                destinationName,
                headCount,
                pickUpDate,
                returnDate,
                currencyTypeId,
            }) =>
                await axiosRequest({
                    url: "/reservations/search",
                    method: "post",
                    body: {
                        originLat,
                        originLon,
                        originName,
                        destinationLat,
                        destinationLon,
                        destinationName,
                        headCount,
                        pickUpDate,
                        returnDate,
                        currencyTypeId,
                    },
                }),
            create: async ({
                sessionId,
                languageId,
                firstName,
                lastName,
                email,
                phoneNumber,
                firstWay: {
                    vehicleTypeId,
                    flightNumber,
                    customerNote,
                    originAddressDetail,
                    destinationAddressDetail,
                    extraIds,
                },
                secondWay = null,
            }) =>
                await axiosRequest({
                    url: "/reservations/create",
                    method: "post",
                    body: {
                        sessionId,
                        languageId,
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        firstWay: {
                            vehicleTypeId,
                            flightNumber,
                            customerNote,
                            originAddressDetail,
                            destinationAddressDetail,
                            extraIds,
                        },
                        ...(secondWay && { secondWay }),
                    },
                }),
            track: async ({ reservationId }) =>
                await axiosRequest({
                    url: `/reservations/${reservationId}/track`,
                    method: "get",
                }),
            feedback: async ({ reservationId }) =>
                await axiosRequest({
                    url: `/reservations/${reservationId}/satisfaction`,
                    method: "get",
                }),
            detail: async ({ reservationId }) =>
                await axiosRequest({
                    url: `/reservations/${reservationId}/detail`,
                    method: "get",
                }),
        },
        currency: {
            fetch: async () =>
                await axiosRequest({
                    url: "/lookups/currency-types",
                    method: "get",
                }),
        },
        languages: {
            fetch: async () =>
                await axiosRequest({
                    url: "/lookups/languages",
                    method: "get",
                }),
        },
        unavailableDates: {
            fetch: async () =>
                await axiosRequest({
                    url: "/lookups/unavailable-dates",
                    method: "get",
                }),
        },
        extras: {
            fetch: async () =>
                await axiosRequest({
                    url: "/lookups/extras",
                    method: "get",
                }),
        },
    }

    // Binding
    return <ApiContext.Provider value={valueObject}>{children}</ApiContext.Provider>
}

export default ApiProvider
