import React from "react"
import { Trans } from "react-i18next"
import { Icons } from "../../utilities/Icons"

const ErrorBox = ({ error, className }) => {
    return (
        error && (
            <div className={`col-12 p-0 mb-2 ${className}`}>
                <div className="text-danger d-flex align-items-center gap-2">
                    <div className="icon-error">{Icons.error()}</div>
                    <div className="text-sentence fw-600">
                        <Trans>{error}</Trans>
                    </div>
                </div>
            </div>
        )
    )
}

export default ErrorBox
