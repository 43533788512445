import React from "react"
import { useTranslation } from "react-i18next"
import { useApp } from "../../providers/AppProvider"

const LangSwitch = () => {
    // States and Hooks
    const { i18n } = useTranslation()
    const { changeLanguage } = useApp()

    // Methods

    // Render
    return (
        <div className="d-flex lang-switch">
            <div>
                <button
                    onClick={() => changeLanguage("en")}
                    className={`btn text-white ${
                        i18n.language === AppLangs.English && "active-lang"
                    }`}
                >
                    EN
                </button>
            </div>
            <div>
                <button
                    onClick={() => changeLanguage("tr")}
                    className={`btn text-white ${
                        i18n.language === AppLangs.Turkish && "active-lang"
                    }`}
                >
                    TR
                </button>
            </div>
        </div>
    )
}

export const AppLangs = {
    Turkish: "tr",
    English: "en",
}

export default LangSwitch
