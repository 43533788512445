import React from "react"
import { Trans } from "react-i18next"
import { useTrack } from "../../providers/TrackProvider"

const DriverInformation = () => {
    // States and Hooks
    const { detailData } = useTrack()
    const DriverInformationData = [
        {
            label: "Vehicle",
            value: detailData?.vehicleType?.name,
        },
        {
            label: "Plate",
            value: detailData?.vehicle?.plate,
        },
        {
            label: "Flight Number",
            value: detailData?.flightNumber,
        },
    ]

    // Render
    return (
        <div className="w-100 bg-white shadow-sm p-4">
            <div className="driver-information">
                <div className="d-flex gap-3 align-items-center align-items-sm-start flex-column flex-sm-row mb-1 mb-sm-4 border-bottom pb-4">
                    <div>
                        <img
                            src={
                                detailData?.vehicleType?.photo
                                    ? `${process.env.REACT_APP_API_BASE_URL}/img/${detailData?.vehicleType.photo}`
                                    : "https://dummyimage.com/150x150/333/fff"
                            }
                            className="image"
                            alt="Profile picture"
                        />
                    </div>
                    <div className="w-100 text-center text-sm-start mt-1">
                        <div className="h5 fw-500 mb-0">
                            {detailData?.driver?.firstName + " " + detailData?.driver?.lastName}
                        </div>
                        <div className="fw-600 fs-14px">
                            <Trans>{detailData?.driver?.phoneNumber}</Trans>
                        </div>
                    </div>
                    <div className="fw-600">
                        <span className="fs-20px">
                            <Trans>Total</Trans>:
                        </span>{" "}
                        {detailData?.totalPrice + " " + detailData.currencyType}
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-4">
                    {DriverInformationData.map(({ label, value }, index) => {
                        return (
                            <div
                                key={index}
                                className={`w-100 py-2 py-sm-0 d-flex flex-column gap-1 text-center text-sm-start ${
                                    index !== DriverInformationData.length - 1 &&
                                    "border-bottom border-sm-bottom-0 border-sm-end"
                                }`}
                            >
                                <div className="fw-400">
                                    <Trans>{label}</Trans>
                                </div>
                                <div className="fw-500">{value}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default DriverInformation
