import React from "react"
import CarSelect from "./CarSelect"
import { useApp } from "../../providers/AppProvider"
import P2PIndicator from "./P2PIndicator"

const OneWayTripSelect = () => {
    // States and Hooks
    const { whenValue, searchItems, firstWayVehicleType, setFirstWayVehicleType } = useApp()
    const array = searchItems.options
    console.log(array)

    // Render
    return (
        <div className="row m-0 align-items-center scroll-mt" id="trip-select">
            <div className="col-12">
                <P2PIndicator isFromToWhere={true} />
                <hr />
            </div>
            {array.length > 0 && (
                <>
                    {array.map(_ => {
                        return (
                            <div className="col-12 col-xl-6 mb-3" key={_.carTypeId}>
                                <CarSelect
                                    imgName={_.image}
                                    carTitle={_.car}
                                    capacity={11}
                                    date={whenValue.toLocaleDateString("en-GB", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                    })}
                                    active={firstWayVehicleType?.vehicleTypeId === _.carTypeId}
                                    selectHandler={() => {
                                        setFirstWayVehicleType(_)
                                    }}
                                    currencyType="₺"
                                    price={_.price}
                                />
                            </div>
                        )
                    })}
                </>
            )}
            {array.length === 0 && <div className="fw-bold text-center">Nothing Found !</div>}
        </div>
    )
}

export default OneWayTripSelect
