import React from "react"
import {
    ServicesIcon1,
    ServicesIcon2,
    ServicesIcon3,
    ServicesIcon4,
} from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"

const Services = () => {
    const { t } = useTranslation()
    const ServicesData = [
        {
            icon: ServicesIcon1,
            title: t("Airport Transfer"),
            description: t(
                "Transportation to Antalya Airport or to another point from Antalya Airport will not be a question mark in your mind. We will take you to the location you want comfortably and safely."
            ),
        },
        {
            icon: ServicesIcon2,
            title: t("Services Special Needs"),
            description: t(
                "We are with you at any moment. We can plan your transportation not only for your business trips but also for your trips regarding special occasions and travels. Let us take you to the point you want and you enjoy the moment"
            ),
        },
        {
            icon: ServicesIcon3,
            title: t("Comfortable and Safe Travel"),
            description: t(
                "We have brought together safe transportation and comfort. With our guest oriented service approach, you can trust us for your comfort and safety."
            ),
        },
        {
            icon: ServicesIcon4,
            title: t("Professional and Trained Staff"),
            description: t(
                "In addition to experience we have gained for more than 40 years, your transportation is in safe hands with our professional and trained staff."
            ),
        },
    ]
    return (
        <div className="px-0 py-2 py-sm-5 p-lg-5 bg-secondary">
            <div className="row m-0 my-5">
                <div className="h1 text-center text-primary fw-bold m-0">
                    <Trans>Services</Trans>
                </div>
                <div className="col-lg-7 col-10 mx-auto fw-lighter mb-4">
                    <div className="text-primary text-center mt-4 lh-200pr">
                        <div>
                            <Trans>
                                We are with you with our best drivers and most comfortable vehicles
                                to make your journeys easier.
                            </Trans>{" "}
                            <div className="text-center">
                                <Trans>Check out our services:</Trans>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 mt-4 px-1 px-lg-5">
                    {ServicesData.map(({ icon, title, description }, index) => {
                        return (
                            <div key={index} className="col-lg-6 col-12 mb-3">
                                <div className="border border-primary p-3 py-lg-5 px-lg-4 d-flex flex-column flex-sm-row align-items-center gap-4 h-100">
                                    <div className="d-none d-lg-block">
                                        <img
                                            src={icon}
                                            className="services-icon-small services-icon-sm-big"
                                            alt="Icon"
                                        />
                                    </div>
                                    <div>
                                        <div className="d-flex d-lg-none pb-3 align-items-center">
                                            <img
                                                src={icon}
                                                className="services-icon-small services-icon-sm-big pe-3 pe-lg-0"
                                                alt="Icon"
                                            />
                                            <div className="text-primary h5 fw-600 m-0">
                                                {title}
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block text-primary h5 fw-600">
                                            {title}
                                        </div>
                                        <div className="fs-14px text-primary fw-lighter lh-200pr">
                                            {description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Services
