import { sleep } from "../functions/sleep"
import React, { useState, createContext, useContext, useEffect } from "react"
import { ContactFormSchema } from "../utilities/FormValidations"
import { scrollIntoViewById } from "../functions/scrollIntoViewById"

const ContactFormContext = createContext()
export const useContactForm = () => useContext(ContactFormContext)

const ContactFormProvider = ({ children }) => {
    // States and Hooks
    const [contactFirstName, setContactFirstName] = useState("")
    const [contactLastName, setContactLastName] = useState("")
    const [contactEmail, setContactEmail] = useState("")
    const [contactPhoneNumber, setContactPhoneNumber] = useState("")
    const [contactNote, setContactNote] = useState("")
    const [error, setError] = useState("")
    const [submitLoading, setSubmitLoading] = useState(false)
    const [isContactSuccessAlertOpen, setIsContactSuccessAlertOpen] =
        useState(false)

    // Methods
    const send = async () => {
        setSubmitLoading(true)
        await sleep(3000)
        setSubmitLoading(false)
        setIsContactSuccessAlertOpen(true)
    }

    const closeContactSuccessAlert = () => setIsContactSuccessAlertOpen(false)

    const onSendButtonClick = e => {
        e.preventDefault()
        setError("")
        scrollIntoViewById("contact-form")

        const validationObject = {
            firstName: contactFirstName,
            lastName: contactLastName,
            email: contactEmail,
            gsmNumber: contactPhoneNumber,
            note: contactNote,
        }

        ContactFormSchema.validate(validationObject)
            .then(send)
            .catch(error => {
                setError(error.message)
            })
    }

    const clearContactForm = () => {
        setContactFirstName("")
        setContactLastName("")
        setContactEmail("")
        setContactPhoneNumber("")
        setContactNote("")
    }

    useEffect(() => {
        if (isContactSuccessAlertOpen) clearContactForm()
    }, [isContactSuccessAlertOpen])

    // Binding
    const valueObject = {
        // States
        contactFirstName,
        setContactFirstName,

        contactLastName,
        setContactLastName,

        contactEmail,
        setContactEmail,

        contactPhoneNumber,
        setContactPhoneNumber,

        contactNote,
        setContactNote,

        error,
        submitLoading,
        isContactSuccessAlertOpen,

        // Methods
        onSendButtonClick,
        closeContactSuccessAlert,
    }

    // Render
    return (
        <ContactFormContext.Provider value={valueObject}>
            {children}
        </ContactFormContext.Provider>
    )
}

export default ContactFormProvider
