import logo from "../images/cyprobus.png"
import logoWhite from "../images/logo-white.svg"
import homeHeroBackground from "../images/bg-home-hero.png"
import minibus from "../images/minibus.png"
import vito from "../images/vito.png"
import tursab from "../images/tursab.png"
import transferPlaceholder from "../images/transfer-placeholder.png"
import locationToLocation from "../images/location-to-location.svg"
import map from "../images/map.png"
import learnMoreBackground from "../images/bg-learn-more.png"
import newsLetterBackground from "../images/bg-news-letter.svg"
import servicesHero from "../images/services-hero.png"
import servicesHeroBackground from "../images/bg-services-hero.svg"
import footerBackground from "../images/footer-bg.svg"
import aboutUsBackgroundLeft from "../images/bg-about-us-left.svg"
import aboutUsBackgroundRight from "../images/bg-about-us-right.svg"
import aboutUsHeroBackgroundLarge from "../images/bg-about-us-hero-large.png"
import aboutUsHeroBackgroundSmall from "../images/bg-about-us-hero-small.png"
import aboutUsCreditBackground from "../images/about-us-credit-bg.svg"
import historyHeroBackground from "../images/bg-history-hero.png"
import aspendos from "../images/aspendos.png"
import karain from "../images/karain.png"
import phaselis from "../images/phaselis.png"
import bgPaymentSuccess from "../images/bg-payment-success.svg"
import bgPaymentFail from "../images/bg-payment-fail.svg"
import layoutBackground from "../images/bg-layout.svg"

// Icons
import carCityTransfer from "../images/icons/car-city-transfer.svg"
import busCityTransfer from "../images/icons/bus-city-transfer.svg"
import cityTours from "../images/icons/city-tours.svg"
import historyTours from "../images/icons/history-tours.svg"
import progressBarPaymentActive from "../images/icons/progress-bar-payment-active.svg"
import progressBarPaymentActiveTr from "../images/icons/progress-bar-payment-active-tr.svg"
import progressBarSearchActive from "../images/icons/progress-bar-search-active.svg"
import progressBarSearchActiveTr from "../images/icons/progress-bar-search-active-tr.svg"
import user from "../images/icons/user.svg"
import mobile from "../images/icons/mobile.svg"
import email from "../images/icons/email.svg"
import calendar from "../images/icons/calendar.svg"
import tickCircle from "../images/icons/tick-circle.svg"
import tickCircleActive from "../images/icons/tick-circle-active.svg"
import clock from "../images/icons/clock.svg"
import building from "../images/icons/building.svg"
import addCircle from "../images/icons/add-circle.svg"
import addCircleAlt from "../images/icons/add-circle-alt.svg"
import searchZoomIn from "../images/icons/search-zoom-in.svg"
import card from "../images/icons/card.svg"
import lock from "../images/icons/lock.svg"
import messageQuestion from "../images/icons/message-question.svg"
import arrowRight from "../images/icons/arrow-right.svg"
import arrowRightAlt from "../images/icons/arrow-right-alt.svg"
import geo from "../images/icons/geo.svg"
import geoAlt from "../images/icons/geo-alt.svg"
import envelope from "../images/icons/envelope.svg"
import telephone from "../images/icons/telephone.svg"
import telephoneAlt from "../images/icons/telephone-alt.svg"
import facebook from "../images/icons/facebook.svg"
import facebookAlt from "../images/icons/facebook-alt.svg"
import instagram from "../images/icons/instagram.svg"
import instagramAlt from "../images/icons/instagram-alt.svg"
import twitter from "../images/icons/twitter.svg"
import twitterAlt from "../images/icons/twitter-alt.svg"
import servicesIcon1 from "../images/icons/services-icon-1.svg"
import servicesIcon2 from "../images/icons/services-icon-2.svg"
import servicesIcon3 from "../images/icons/services-icon-3.svg"
import servicesIcon4 from "../images/icons/services-icon-4.svg"
import learnMorePlay from "../images/icons/learn-more-play.svg"
import hamburger from "../images/icons/hamburger.svg"
import cross from "../images/icons/cross.svg"
import telegram from "../images/icons/telegram.svg"
import share from "../images/icons/share.svg"
import linkedIn from "../images/icons/linked-in.svg"
import messageQuestionRect from "../images/icons/message-question-rect.svg"
import arrowRightTailed from "../images/icons/arrow-right-tailed.svg"
import add from "../images/icons/add.svg"
import minus from "../images/icons/minus.svg"
import caretDown from "../images/icons/caret-down.svg"
import successTickCircle from "../images/icons/success-tick-circle.svg"
import failCloseCircle from "../images/icons/fail-close-circle.svg"
import flight from "../images/icons/flight.svg"

export const Logo = logo
export const LogoWhite = logoWhite
export const HomeHeroBackground = homeHeroBackground
export const Minibus = minibus
export const Vito = vito
export const TransferPlaceholder = transferPlaceholder
export const LocationToLocation = locationToLocation
export const Map = map
export const Tursab = tursab
export const LearnMoreBackground = learnMoreBackground
export const NewsLetterBackground = newsLetterBackground
export const ServicesHero = servicesHero
export const ServicesHeroBackground = servicesHeroBackground
export const FooterBackground = footerBackground
export const AboutUsBackgroundLeft = aboutUsBackgroundLeft
export const AboutUsBackgroundRight = aboutUsBackgroundRight
export const AboutUsHeroBackgroundLarge = aboutUsHeroBackgroundLarge
export const AboutUsHeroBackgroundSmall = aboutUsHeroBackgroundSmall
export const AboutUsCreditBackground = aboutUsCreditBackground
export const HistoryHeroBackground = historyHeroBackground
export const Aspendos = aspendos
export const Karain = karain
export const Phaselis = phaselis
export const BgPaymentSuccess = bgPaymentSuccess
export const BgPaymentFail = bgPaymentFail
export const LayoutBackground = layoutBackground

// Icons
export const CarCityTransfer = carCityTransfer
export const BusCityTransfer = busCityTransfer
export const CityTours = cityTours
export const HistoryTours = historyTours
export const ProgressBarPaymentActive = progressBarPaymentActive
export const ProgressBarPaymentActiveTr = progressBarPaymentActiveTr
export const ProgressBarSearchActive = progressBarSearchActive
export const ProgressBarSearchActiveTr = progressBarSearchActiveTr
export const User = user
export const Mobile = mobile
export const Email = email
export const Calendar = calendar
export const TickCircle = tickCircle
export const TickCircleActive = tickCircleActive
export const Clock = clock
export const Building = building
export const AddCircle = addCircle
export const AddCircleAlt = addCircleAlt
export const SearchZoomIn = searchZoomIn
export const Card = card
export const Lock = lock
export const MessageQuestion = messageQuestion
export const ArrowRight = arrowRight
export const ArrowRightAlt = arrowRightAlt
export const Geo = geo
export const GeoAlt = geoAlt
export const Envelope = envelope
export const Telephone = telephone
export const TelephoneAlt = telephoneAlt
export const Facebook = facebook
export const FacebookAlt = facebookAlt
export const Instagram = instagram
export const InstagramAlt = instagramAlt
export const Twitter = twitter
export const TwitterAlt = twitterAlt
export const ServicesIcon1 = servicesIcon1
export const ServicesIcon2 = servicesIcon2
export const ServicesIcon3 = servicesIcon3
export const ServicesIcon4 = servicesIcon4
export const LearnMorePlay = learnMorePlay
export const Hamburger = hamburger
export const Cross = cross
export const Telegram = telegram
export const Share = share
export const LinkedIn = linkedIn
export const MessageQuestionRect = messageQuestionRect
export const ArrowRightTailed = arrowRightTailed
export const Add = add
export const Minus = minus
export const CaretDown = caretDown
export const SuccessTickCircle = successTickCircle
export const FailCloseCircle = failCloseCircle
export const Flight = flight
