import React from "react"
import { useApp } from "../../providers/AppProvider"
import { useSearch } from "../../providers/SearchProvider"
import { Icons } from "../../utilities/Icons"
import Shimmer from "../template/Skeletons/Shimmer"
import SkeletonElement from "../template/Skeletons/SkeletonElement"

const P2PIndicator = ({ isFromToWhere }) => {
    // States and Hooks
    const { whereValue, fromValue } = useApp()
    const { searchButtonLoading } = useSearch()

    // Methods
    const filterText = string => {
        if (string.length > 30) return string.slice(0, 27) + "..."
        return string
    }

    // Render
    return (
        <>
            {searchButtonLoading ? (
                <div className="col-12 p-0">
                    <div className="skeleton-wrapper py-1 my-0 light">
                        <div className="d-flex flex-column flex-sm-row align-items-center gap-2 fw-bold text-center text-sm-start">
                            <SkeletonElement type="title" />
                            <span className="p2p-arrow">
                                {Icons.arrowRight()}
                            </span>
                            <SkeletonElement type="title" />
                        </div>
                        <Shimmer />
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column flex-sm-row align-items-center gap-2 fw-bold text-center text-sm-start">
                    <span>
                        {filterText(isFromToWhere ? fromValue : whereValue)}
                    </span>
                    <span className="p2p-arrow">{Icons.arrowRight()}</span>
                    <span>
                        {filterText(isFromToWhere ? whereValue : fromValue)}
                    </span>
                </div>
            )}
        </>
    )
}

export default P2PIndicator
