import React from "react"
import { LayoutBackground } from "../../utilities/ImageImports"

const FeaturedPage = ({ children }) => {
    return (
        <div
            className="featured-page"
            style={{
                backgroundImage: `url(${LayoutBackground})`,
            }}
        >
            {children}
        </div>
    )
}

export default FeaturedPage
