import "swiper/css"
import "swiper/css/effect-cards"

import React from "react"
import { Trans } from "react-i18next"
import { FreeMode, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useHome } from "../../providers/home/HomeProvider"
import { LocationToLocation } from "../../utilities/ImageImports"

const TransferPrices = () => {
    // States and Hooks
    const { onBookNowButtonClick } = useHome()
    const isMobile = window.innerWidth <= 576
    const isTablet = window.innerWidth <= 992
    const isDesktop = window.innerWidth > 992

    // Render
    return (
        <div className="pt-5 pb-5 bg-white bg-lg-light-gray">
            <div className="col-lg-10 col-11 mx-auto">
                <div className="h3 lh-40px text-center py-5 fw-bold">
                    <Trans>
                        Antalya Airport Transfer Prices with Comfortable, Safe & Luxury Private
                        Vehicles
                    </Trans>
                </div>
            </div>
            <Swiper
                slidesPerView={isMobile ? 1.2 : isTablet ? 2 : isDesktop ? 3.5 : "auto"}
                spaceBetween={10}
                freeMode={true}
                loop={true}
                modules={[FreeMode, Scrollbar]}
            >
                <div className="transfer-prices d-flex flex-column flex-sm-row overflow-auto my-2 gap-3 mx-3">
                    {data.map((item, index) => {
                        // States and Hooks

                        // Render
                        return (
                            <SwiperSlide key={index}>
                                <div key={index} className="transfer-prices-item w-100 border">
                                    <div className="w-100 d-flex flex-column gap-2 justify-content-center">
                                        <div className="text-center">
                                            <img
                                                src={item.route_image_url}
                                                className="transfer-prices-item-main-image"
                                                alt="Transfer placeholder"
                                            />
                                        </div>
                                        <img
                                            src={LocationToLocation}
                                            className="mt-3 mb-2 img-fluid"
                                            alt="Location to location"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between fw-500 fs-16px">
                                        <div>
                                            <Trans>{item.from}</Trans>
                                        </div>
                                        <div>{item.to}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </div>
            </Swiper>
        </div>
    )
}
export default TransferPrices

const data = [
    {
        route_image_url: "/route-1.jpg",
        from: "Ercan Havalimanı",
        to: "Girne",
    },
    {
        route_image_url: "/route-2.jpg",
        from: "Ercan Havalimanı",
        to: "Lefkoşa",
    },
    {
        route_image_url: "/route-3.jpg",
        from: "Ercan Havalimanı",
        to: "Gazimağusa",
    },
    {
        route_image_url: "/route-2.jpg",
        from: "Ercan Havalimanı",
        to: "Bafra",
    },
]
