import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router"
import { sleep } from "../../functions/sleep"
import { useHeader } from "../../providers/HeaderProvider"
import { useApp } from "../../providers/AppProvider"
import { AppRoutes } from "../../utilities/AppRoutes"
import { OrderTrackingSearchSchema } from "../../utilities/FormValidations"
import { User } from "../../utilities/ImageImports"
import TextInput from "../complete-information/TextInput"
import Loader from "../template/Loader"
import ErrorBox from "../template/ErrorBox"
import { Trans, useTranslation } from "react-i18next"
import { useApi } from "../../providers/ApiProvider"

const OrderTrackingModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { isOrderTrackingModalOpen: isOpen, closeOrderTrackingModal: closeModal } = useHeader()

    const { setLoadingApp } = useApp()
    const { reservations } = useApi()

    const navigate = useNavigate()
    const [loadingSubmitButton, setLoadingSubmitButton] = useState(false)
    const [reservationCode, setReservationCode] = useState("")
    const [error, setError] = useState("")

    // Methods
    const clearOrderTrackingModalForm = () => {
        setReservationCode("")
    }

    const onSubmitButtonClick = async e => {
        e.preventDefault()
        setError("")
        setLoadingSubmitButton(true)

        const response = await OrderTrackingSearchSchema.validate({
            reservationCode,
        }).catch(error => setError(error.message))

        if (response) {
            const { error } = await reservations.detail({ reservationId: reservationCode })
            if (error) {
                setLoadingApp(false)
                setLoadingSubmitButton(false)
                return setError(t("Reservation could not be tracked"))
            }
            if (window.location.pathname.includes(AppRoutes.orderTracking)) {
                setLoadingApp(true)
                await sleep(2000)
            }
            navigate(AppRoutes.orderTracking + `/${reservationCode}`)
            closeModal()
            clearOrderTrackingModalForm()
        }

        setLoadingApp(false)
        setLoadingSubmitButton(false)
    }

    // Render
    return (
        <Modal show={isOpen} onHide={closeModal} fullscreen="sm-down" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans>Order Tracking</Trans>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2 p-sm-5">
                    <form>
                        <div className="fw-500 mb-2">
                            <Trans>Please insert your reservation code here</Trans>:
                        </div>
                        <ErrorBox error={error} />
                        <div className="col-12">
                            <TextInput
                                icon={User}
                                placeholder={t("Reservation code")}
                                value={reservationCode}
                                setValue={setReservationCode}
                            />
                        </div>
                        <div className="col-12 col-md-6 ms-auto">
                            <button
                                type="submit"
                                className="w-100 btn btn-lg btn-secondary fw-600 mt-2"
                                disabled={loadingSubmitButton}
                                onClick={onSubmitButtonClick}
                            >
                                {loadingSubmitButton ? <Loader /> : <Trans>Submit</Trans>}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OrderTrackingModal
