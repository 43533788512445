import React from "react"
import LangSwitch from "./LangSwitch"
import { Trans } from "react-i18next"
import HeaderOverlay from "./HeaderOverlay"
import { Link, NavLink } from "react-router-dom"
import { AppRoutes } from "../../utilities/AppRoutes"
import { HeaderLinks } from "../../utilities/StaticData"
import { useHeader } from "../../providers/HeaderProvider"
import { Hamburger, Logo } from "../../utilities/ImageImports"
import OrderTrackingModal from "../order-tracking/OrderTrackingModal"
import { CompanyPhoneNumber } from "../../utilities/Config"

const Header = () => {
    // Hooks
    const { openMenu, closeMenu, isMenuOpen, openOrderTrackingModal } = useHeader()

    // Render
    return (
        <div className="header sticky-top">
            <OrderTrackingModal />
            <button
                onClick={closeMenu}
                className="btn p-0 rounded-0 header-menu-mobile-gap d-none"
            />

            {isMenuOpen && <HeaderOverlay />}
            <div className="shadow-sm bg-white">
                <div className="py-4 px-4 px-lg-5 border-bottom">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 d-none d-lg-block">
                            <LangSwitch />
                        </div>

                        <div className="w-100 text-start text-lg-center">
                            <div className="col-10 col-sm-6 mx-lg-auto">
                                <Link to={AppRoutes.home}>
                                    <img src={Logo} alt="Logo" className="img-fluid px-0" />
                                </Link>
                            </div>
                        </div>
                        <div className="w-100">
                            <button
                                onClick={openOrderTrackingModal}
                                className="btn btn-secondary rounded-0 fw-bold py-3 px-3 d-none d-lg-block ms-auto"
                            >
                                {window.location.pathname.includes(AppRoutes.orderTracking) ? (
                                    <Trans>New Order Tracking</Trans>
                                ) : (
                                    <Trans>Track Reservation</Trans>
                                )}
                            </button>
                        </div>

                        <button onClick={openMenu} className="btn p-0 d-block d-lg-none">
                            <img src={Hamburger} alt="Menu icon" />
                        </button>
                    </div>
                </div>
                <div className="d-none d-lg-flex align-items-center justify-content-between px-4 px-lg-5">
                    <div className="w-100" />
                    <div className="w-100 d-flex align-items-center justify-content-center py-3 gap-4">
                        {HeaderLinks.map(({ label, link }, index) => {
                            return (
                                <NavLink
                                    key={index}
                                    to={link}
                                    className="btn fw-normal rounded-0 p-0 text-nowrap"
                                >
                                    <Trans>{label}</Trans>
                                </NavLink>
                            )
                        })}
                    </div>
                    <div className="w-100 d-lg-flex d-none justify-content-end">
                        <Link to={AppRoutes.contact} className="text-contact-us">
                            {CompanyPhoneNumber}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
