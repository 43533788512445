import React from "react"
import NewsLetter from "../components/home/NewsLetter"
import Hero from "../components/services/Hero"
import OurServices from "../components/services/OurServices"
import Layout from "../components/template/Layout"

const Services = () => {
    return (
        <Layout>
            <div className="bg-light-gray services-page">
                <Hero />
                <OurServices />
                <NewsLetter />
            </div>
        </Layout>
    )
}

export default Services
