import React from "react"
import { NavLink } from "react-router-dom"
import { AppRoutes } from "../../utilities/AppRoutes"
import { useHeader } from "../../providers/HeaderProvider"
import { Cross } from "../../utilities/ImageImports"
import { HeaderLinks } from "../../utilities/StaticData"
import LangSwitch from "./LangSwitch"
import { Trans } from "react-i18next"

const HeaderOverlay = () => {
    // States and Hooks
    const { isMenuOpenDuplicate, closeMenu, openOrderTrackingModal } = useHeader()

    // const showCurrencyList = CurrencySelectPermittedPaths.includes(
    //     window.location.pathname
    // )

    // Methods

    // UseEffects

    // Render
    return (
        <div
            className={`header-menu-mobile shadow ${
                isMenuOpenDuplicate ? "menu-open-animation" : "menu-close-animation"
            }`}
        >
            <div className="d-flex align-items-start justify-content-between flex-column h-100">
                <div className="w-100">
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="btn p-0" onClick={closeMenu}>
                            <img src={Cross} className="img-fluid cross-icon" alt="Cross icon" />
                        </button>
                    </div>
                    <div className="mt-4 mb-4 d-flex flex-wrap gap-2 justify-content-between align-items-center">
                        <LangSwitch />
                    </div>
                    <div className="mt-2">
                        {HeaderLinks.map(({ label, link }, index) => {
                            return (
                                <div className="mb-2" key={index}>
                                    <NavLink
                                        to={link}
                                        className="btn p-0 ps-1 mb-3 fw-500 rounded-0"
                                    >
                                        <Trans>{label}</Trans>
                                    </NavLink>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-12">
                        <button
                            onClick={openOrderTrackingModal}
                            className="btn btn-secondary rounded-0 fw-bold py-3 px-3 d-block d-lg-none w-100 mt-3"
                        >
                            <Trans>
                                {window.location.pathname.includes(AppRoutes.orderTracking)
                                    ? "New Order Tracking"
                                    : "Order Tracking"}
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderOverlay
