import * as yup from "yup"

export const RoundTripFormSchema = yup.object().shape({
    people: yup
        .object()
        .shape({
            adult: yup.number().min(1).required(),
            children: yup.number().min(0).required(),
            infant: yup.number().min(0).required(),
        })
        .required("Number of people field is required"),
    return: yup.string().required("Return date is required"),
    when: yup.string().required("Arrive date is required"),
    where: yup.string().required("Destination place is required"),
    from: yup.string().required("Origin place is required"),
})

export const OneWayTripFormSchema = yup.object().shape({
    people: yup
        .object()
        .shape({
            adult: yup.number().min(1).required(),
            children: yup.number().min(0).required(),
            infant: yup.number().min(0).required(),
        })
        .required("Number of people field is required"),
    return: yup.string().optional(),
    when: yup.string().required("Arrive date is required"),
    where: yup.string().required("Destination place is required"),
    from: yup.string().required("Origin place is required"),
})

export const OrderTrackingSearchSchema = yup.object().shape({
    reservationCode: yup.string().required("Reservation code field cannot be left empty"),
})

// Complete Information Page
export const PaymentFormSchema = yup.object().shape({
    cardCvv: yup.string().required("CVV number is required").length(3, "Invalid CVV number"),
    cardExpireDate: yup
        .string()
        .required("Expire date is required")
        .length(5, "Invalid expire date"),
    cardNumber: yup.string().required("Card number is required").length(16, "Invalid card number"),
})

export const OneWayCompleteInformationFormSchema = yup.object().shape({
    firstWayFlightNumber: yup.string().optional(),
    firstWayDestinationDetails: yup.string().required("First way destination details is required"),
    firstWayOriginDetails: yup.string().required("First way origin details is required"),
    note: yup.string().optional(),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .test("len", "Phone number is not valid", val => val.length === 10),
    email: yup.string().email("Email is not valid").required("Email is required"),
    lastname: yup.string().required("Last name is required"),
    firstname: yup.string().required("First name is required"),
})

export const RoundTripCompleteInformationFormSchema = yup.object().shape({
    secondWayFlightNumber: yup.string().optional(""),
    secondWayDestinationDetails: yup
        .string()
        .required("Second way destination details is required"),
    secondWayOriginDetails: yup.string().required("Second way origin details is required"),
    firstWayFlightNumber: yup.string().optional(),
    firstWayDestinationDetails: yup.string().required("First way destination details is required"),
    firstWayOriginDetails: yup.string().required("First way origin details is required"),
    note: yup.string().optional(),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .test("len", "Phone number is not valid", val => val.length === 10),
    email: yup.string().email("Email is not valid").required("Email is required"),
    lastname: yup.string().required("Last name is required"),
    firstname: yup.string().required("First name is required"),
})

// Contact Page
export const ContactFormSchema = yup.object().shape({
    note: yup
        .string()
        .min(10, "Text message cannot be less than 10 characters")
        .required("Text message field is required"),
    gsmNumber: yup
        .string()
        .required("Phone number is required")
        .test("len", "Phone number is not valid", val => val.length === 10),
    email: yup.string().email("Email is not valid").required("Email is required"),
    lastName: yup.string().required("Last name is required"),
    firstName: yup.string().required("First name is required"),
})

export const OneWayTripSelectSchema = yup.object().shape({
    firstWayVehicleType: yup.object().nullable().required("Select first way trip"),
})

export const RoundWayTripSelectSchema = yup.object().shape({
    secondWayVehicleType: yup.object().nullable().required("Select second way trip"),
    firstWayVehicleType: yup.object().nullable().required("Select first way trip"),
})
