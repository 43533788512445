import { useNavigate } from "react-router"
import { AppRoutes } from "../utilities/AppRoutes"
import { createContext, useContext, useState } from "react"
import {
    OneWayTripSelectSchema,
    RoundWayTripSelectSchema,
    OneWayTripFormSchema,
    RoundTripFormSchema,
} from "../utilities/FormValidations"
import { useApp } from "./AppProvider"
import { scrollIntoViewById } from "../functions/scrollIntoViewById"

const SearchContext = createContext()
export const useSearch = () => useContext(SearchContext)

const SearchProvider = ({ children }) => {
    // States and Hooks
    const navigate = useNavigate()
    const [isNoCarFoundModalOpen, setIsNoCarFoundModalOpen] = useState(false)
    const [tripSelectErrors, setTripSelectErrors] = useState("")
    const [searchTripsErrors, setSearchTripsErrors] = useState("")
    const [completeButtonLoading, setCompleteButtonLoading] = useState(false)
    const [searchButtonLoading, setSearchButtonLoading] = useState(false)

    const {
        fromValue,
        whereValue,
        whenValue,
        returnValue,
        TripTypes,
        peopleValue,
        currentTripType,
        searchTrips,
        setSearchItems,
        firstWayVehicleType,
        secondWayVehicleType,
        clearTripSelectForm,
        showTripSelect,
        setShowTripSelect,
    } = useApp()

    // Methods
    const completeInformation = async () => {
        setCompleteButtonLoading(true)
        navigate(AppRoutes.completeInformation)
        setCompleteButtonLoading(false)
    }

    const getFormSchema = () => {
        return currentTripType === TripTypes.RoundTrip ? RoundTripFormSchema : OneWayTripFormSchema
    }

    const getTripSelectSchema = () => {
        return currentTripType === TripTypes.RoundTrip
            ? RoundWayTripSelectSchema
            : OneWayTripSelectSchema
    }

    const onCompleteInformationButtonClick = async e => {
        e.preventDefault()
        setTripSelectErrors("")
        setSearchTripsErrors("")
        scrollIntoViewById("trip-select")

        const validationObject = {
            firstWayVehicleType: firstWayVehicleType,
        }

        if (currentTripType === TripTypes.RoundTrip)
            validationObject.secondWayVehicleType = secondWayVehicleType

        getTripSelectSchema()
            .validate(validationObject)
            .then(completeInformation)
            .catch(error => {
                setTripSelectErrors(error.errors)
            })
    }

    const onSearchButtonClick = async e => {
        e.preventDefault()
        localStorage.clear()
        setSearchTripsErrors("")
        setTripSelectErrors("")
        clearTripSelectForm()
        scrollIntoViewById("search-trip")

        const validationObject = {
            from: fromValue,
            where: whereValue,
            when: whenValue,
            people: {
                adult: peopleValue.adultCount,
                children: peopleValue.childrenCount,
                infant: peopleValue.infantCount,
            },
        }

        if (currentTripType === TripTypes.RoundTrip) validationObject.return = returnValue

        setSearchButtonLoading(true)
        const validation = await getFormSchema()
            .validate(validationObject)
            .catch(error => {
                setSearchTripsErrors(error.errors)
            })

        if (validation) {
            setShowTripSelect(true)

            const response = await searchTrips()
            console.log("=======>>>>", response.data.options)
            setSearchItems(response.data.options)
        }
        setSearchButtonLoading(false)
    }

    // Binding
    const valueObject = {
        // States and Hooks
        tripSelectErrors,
        setTripSelectErrors,

        searchTripsErrors,
        setSearchTripsErrors,

        completeButtonLoading,
        setCompleteButtonLoading,

        searchButtonLoading,
        setSearchButtonLoading,

        isNoCarFoundModalOpen,
        setIsNoCarFoundModalOpen,

        showTripSelect,
        setShowTripSelect,

        // Methods
        onSearchButtonClick,
        onCompleteInformationButtonClick,
    }

    // Render
    return <SearchContext.Provider value={valueObject}>{children}</SearchContext.Provider>
}

export default SearchProvider
