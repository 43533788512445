import React from "react"

const PeopleSelectItem = ({
    title,
    value,
    addFunc,
    subtractFunc,
    className,
    setState,
}) => {
    return (
        <div className={`d-flex align-items-center px-3 ${className}`}>
            <div className="pe-3 fs-12px fw-bold w-50">{title}:</div>
            <div className="input-group w-50">
                <button
                    className="btn btn-outline-secondary px-1 py-0"
                    type="button"
                    onClick={subtractFunc}
                >
                    -
                </button>
                <input
                    type="text"
                    className="form-control px-2 py-0 fs-12px fw-bolder text-center"
                    value={value}
                    onChange={e =>
                        setState(
                            Number(e.target.value) >= 0
                                ? Number(e.target.value)
                                : 0
                        )
                    }
                />
                <button
                    className="btn btn-outline-secondary px-1 py-0"
                    type="button"
                    onClick={addFunc}
                >
                    +
                </button>
            </div>
        </div>
    )
}

export default PeopleSelectItem
