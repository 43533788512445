import React from "react"
import { Link } from "react-router-dom"
import Layout from "../components/template/Layout"
import { AppRoutes } from "../utilities/AppRoutes"
import { Icons } from "../utilities/Icons"
import { ArrowRightTailed, HomeHeroBackground } from "../utilities/ImageImports"
import { Trans } from "react-i18next"

const NotFound = () => {
    return (
        <Layout>
            <div
                className="not-found-page"
                style={{
                    backgroundImage: `url(${HomeHeroBackground})`,
                }}
            >
                <div className="overlay">
                    <div className="h-100 d-flex align-items-center justify-content-center flex-column text-center">
                        <div className="title fw-500">404</div>
                        <div className="fw-light">
                            <Trans>
                                Do not worry, we will help you find the right
                                path
                            </Trans>
                        </div>
                        <div className="mt-4">
                            <Link
                                className="btn btn-outline-light rounded-0 px-4 py-2 fw-500"
                                to={AppRoutes.home}
                            >
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                    <div>{Icons.arrowRightTailed()}</div>
                                    <div>
                                        <Trans>Home Page</Trans>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFound
