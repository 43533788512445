import { useState } from "react"
import { Trans } from "react-i18next"

const FeedbackItem = ({ item }) => {
    // States and Hooks
    const [selectedItem, setSelectedItem] = useState()

    // Render
    return (
        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
            <div className="fw-600">{item.text}</div>
            <div className="d-flex align-items-center justify-content-between gap-2 mb-2 mt-1 w-auto">
                {[1, 2, 3, 4, 5].map(item => {
                    return (
                        <button
                            id={item}
                            onClick={() => setSelectedItem(item)}
                            className={`btn btn-lg rounded-3 px-4 ${
                                item === selectedItem ? "btn-secondary" : "btn-primary "
                            }`}
                        >
                            {item}
                        </button>
                    )
                })}
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4 w-auto fs-12px text-secondary opacity-50 fw-500">
                <div>
                    <Trans>Not satisfied</Trans>
                </div>
                <div>
                    <Trans>Very satisfied</Trans>
                </div>
            </div>
        </div>
    )
}

export default FeedbackItem
