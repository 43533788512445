import React from "react"
import { HomeHeroBackground } from "../../utilities/ImageImports"
import { Trans } from "react-i18next"
import ReservationForm from "./ReservationForm"

const Hero = () => {
    // Render
    return (
        <div
            className="home-hero"
            style={{
                backgroundImage: `url(${HomeHeroBackground})`,
            }}
        >
            <div className="home-hero-overlay p-3 p-lg-5">
                <div className="row m-0 px-lg-3">
                    <div className="col-lg-12 col-12">
                        <div className="home-hero-title text-white mt-4 mb-3 text-center text-sm-start ls-5p fw-normal">
                            <Trans>You focus on your loved ones</Trans>
                            <br />
                            <Trans>leave your transfer to us!</Trans>
                        </div>
                    </div>
                </div>
                <div className="row m-0 px-lg-3">
                    <div className="col-lg-7 col-12">
                        <div className="home-hero-description mb-2 text-light fw-lighter text-center text-sm-start ls-5p">
                            <Trans>Instant travel with affordable options.</Trans>
                        </div>
                    </div>
                </div>
                <ReservationForm />
            </div>
        </div>
    )
}

export default Hero
