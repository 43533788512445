import React, { useState, useEffect } from "react"
import { Icons } from "../../utilities/Icons"

const Alert = ({
    show,
    onClose,
    variant,
    duration,
    autoHide,
    children,
    ...props
}) => {
    const [iconShow, setIconShow] = useState(false)
    const [containerShow, setContainerShow] = useState(false)
    const [contentShow, setContentShow] = useState(false)

    useEffect(() => {
        if (autoHide && show) {
            setTimeout(() => {
                onClose()
            }, duration * 1000)
        }
    }, [show])

    useEffect(() => {
        if (show) {
            setIconShow(true)
            setTimeout(() => {
                setContainerShow(true)
            }, 350)
            setTimeout(() => {
                setContentShow(true)
            }, 750)
        } else {
            setContentShow(false)
            setTimeout(() => {
                setContainerShow(false)
            }, 350)
            setTimeout(() => {
                setIconShow(false)
            }, 750)
        }
    }, [show])

    return (
        <div className="alert d-flex align-items-center p-0">
            <div
                className={`bg-dark rounded-5px p-1 transition-fade ${
                    iconShow ? "opacity-1" : "opacity-0"
                }`}
            >
                <div
                    className={`bg-${variant} rounded-5px p-2 text-center alert-icon-container transition-fade ${
                        iconShow ? "opacity-1" : "opacity-0"
                    }`}
                >
                    <i className="text-white">{Icons[`${variant}Alert`]()}</i>
                </div>
            </div>
            <div
                className={`content-container d-flex align-items-center rounded-top-right-5px rounded-bottom-right-5px text-white bg-dark transition-roll ${
                    containerShow ? "mw-1000px" : "mw-0px"
                }`}
            >
                <div
                    className={`d-flex ps-2 align-items-center transition-fade ${
                        contentShow ? "opacity-1" : "opacity-0"
                    }`}
                >
                    <div className={`pe-5 ${props.className}`}>{children}</div>
                    <button
                        className="btn bg-dark p-2 border-0"
                        onClick={onClose}
                    >
                        <i className="text-white p-1">{Icons.close()}</i>
                    </button>
                </div>
            </div>
        </div>
    )
}

Alert.defaultProps = {
    autoHide: true,
    duration: 3,
    variant: "success",
}

export default Alert
