import React from "react"

const TextInput = ({ icon, placeholder, value, setValue }) => {
    return (
        <div className="input-group">
            <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                <img src={icon} alt="icon" className="form-input-icon" />
            </span>
            <input
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
                className="form-control border-start-0 rounded-0 fw-normal form-input-text ps-0 p-3"
                placeholder={placeholder}
            />
        </div>
    )
}

export default TextInput
