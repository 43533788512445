import { useTranslation } from "react-i18next"
import React, { createContext, useContext, useEffect, useState } from "react"
import { AppRoutes } from "../utilities/AppRoutes"
import { getUserCurrencyByIp } from "../functions/getUserCurrencyByIp"
import axios from "axios"

const AppContext = createContext()
export const useApp = () => useContext(AppContext)

const AppProvider = ({ children }) => {
    // States and Hooks
    const { i18n } = useTranslation()

    const [currencies] = useState([])
    const [routes, setRoutes] = useState([])
    const [fromValue, setFromValue] = useState("")
    const [fromLat, setFromLat] = useState(null)
    const [fromLon, setFromLon] = useState(null)
    const [whereValue, setWhereValue] = useState("")
    const [whereLat, setWhereLat] = useState(null)
    const [whereLon, setWhereLon] = useState(null)
    const [whenValue, setWhenValue] = useState("")
    const [returnValue, setReturnValue] = useState("")
    const [peopleValue, setPeopleValue] = useState({
        adultCount: 2,
        childrenCount: 0,
        infantCount: 0,
    })

    const [firstNameValue, setFirstNameValue] = useState("")
    const [lastNameValue, setLastNameValue] = useState("")
    const [emailValue, setEmailValue] = useState("")
    const [gsmNumberValue, setGsmNumberValue] = useState("")
    const [noteValue, setNoteValue] = useState("")

    const [carValue, setCarValue] = useState("")

    const [userLocation, setUserLocation] = useState("")
    const [currentTripType, setCurrentTripType] = useState(TripTypes.RoundTrip)
    const [loadingApp, setLoadingApp] = useState(true)
    const [searchItems, setSearchItems] = useState(null)

    const [sessionId, setSessionId] = useState("")
    const [firstWayVehicleType, setFirstWayVehicleType] = useState(null)
    const [secondWayVehicleType, setSecondWayVehicleType] = useState(null)
    const [showTripSelect, setShowTripSelect] = useState(false)

    const [unavailableDatesItems, setUnavailableDatesItems] = useState([])
    const [currentLanguage, setCurrentLanguage] = useState("tr")
    const [extras, setExtras] = useState()

    // Methods
    const changeLanguage = lang => {
        i18n.changeLanguage(lang)
        setCurrentLanguage(lang)
    }

    const initialize = async () => {
        await Promise.all([getUserCurrencyByIp(), fetchRoutes(), fetchExtras()])
        setLoadingApp(false)
    }

    const fetchExtras = async () => {
        const { data } = axios.get("https://test.transferix.net/online/cyprobus/extras/")
        setExtras(data)
    }

    const searchTrips = async () => {
        const appObject = {
            fromValue,
            whereValue,
            whenValue,
            returnValue,
            peopleValue,
            currentTripType,
        }

        const searchObject = {
            headCount: Object.values(peopleValue).reduce((t, c) => t + c),
            destination: +routes.find(_ => _.name === fromValue).id,
            arrival: +routes.find(_ => _.name === whereValue).id,
            pickUpDate: whenValue,
            twoWay: currentTripType === TripTypes.RoundTrip,
            arrivalDate: returnValue ? returnValue : null,
        }

        const response = await axios.post(
            "https://test.transferix.net/online/cyprobus/reservations/search/",
            searchObject,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        if (response) {
            console.log(response)
            localStorage.removeItem("prevSearchObject")
            localStorage.setItem("prevSearchObject", JSON.stringify(searchObject))

            localStorage.removeItem("appObject")
            localStorage.setItem("appObject", JSON.stringify(appObject))

            return response
        }
    }

    const fetchRoutes = async () => {
        const { data } = await axios.get("https://test.transferix.net/online/cyprobus/routes/")
        setRoutes(data)
    }

    const clearTripSelectForm = () => {
        setShowTripSelect(false)
        setSearchItems(null)
        setFirstWayVehicleType(null)
        setSecondWayVehicleType(null)
    }

    // UseEffects
    useEffect(() => {
        initialize()
    }, [])

    // Binding
    const valueObject = {
        // States and Hooks
        routes,
        extras,
        TripTypes,
        fromValue,
        fromLat,
        fromLon,
        whereValue,
        whereLat,
        whereLon,
        whenValue,
        returnValue,
        peopleValue,
        firstNameValue,
        lastNameValue,
        emailValue,
        gsmNumberValue,
        noteValue,
        carValue,

        currentTripType,
        userLocation,
        loadingApp,
        searchItems,
        currencies,

        sessionId,
        setSessionId,

        firstWayVehicleType,
        setFirstWayVehicleType,

        secondWayVehicleType,
        setSecondWayVehicleType,

        unavailableDatesItems,
        setUnavailableDatesItems,

        setFromValue,
        setFromLat,
        setFromLon,
        setWhereValue,
        setWhereLat,
        setWhereLon,
        setWhenValue,
        setReturnValue,
        setPeopleValue,
        setFirstNameValue,
        setLastNameValue,
        setEmailValue,
        setGsmNumberValue,
        setNoteValue,
        setCarValue,
        setCurrentTripType,
        setUserLocation,
        setLoadingApp,
        setSearchItems,

        showTripSelect,
        setShowTripSelect,

        currentLanguage,
        setCurrentLanguage,

        // Methods
        changeLanguage,
        searchTrips,
        clearTripSelectForm,
    }

    // Render
    return <AppContext.Provider value={valueObject}>{children}</AppContext.Provider>
}

export default AppProvider

export const TripTypes = {
    RoundTrip: "Round Trip",
    OneWay: "One Way",
}

export const TimezoneToCurrencyMap = {
    America: "USD",
    Europe: "EUR",
}

export const CurrencySelectPermittedPaths = [AppRoutes.home, AppRoutes.search]

export const MIN_OFFSET_FROM_START_RESERVATION = 10_800_000
