import React, { useEffect, useState } from "react"
import { Icons } from "../../utilities/Icons"
import useOnclickOutside from "react-cool-onclickoutside"

const PlacesAutoCompleteInput = ({
    placeValue,
    setPlaceValue,
    placeholder,
    clearOnChange = () => {},
    onOptionCLick = () => {},
    routes = [],
}) => {
    // States and Hooks
    const ready = routes.length > 0
    const [isFocused, setIsFocused] = useState(false)
    const listReference = useOnclickOutside(() => setIsFocused(false))

    // Render
    return (
        <div className="position-relative" ref={listReference}>
            <input
                type="text"
                placeholder={placeholder}
                disabled={!ready}
                className="form-control rounded-0 p-3 m-0 custom-placeholder fw-bold"
                value={placeValue}
                onFocus={() => setIsFocused(true)}
                onChange={e => {
                    clearOnChange()
                    setPlaceValue("")
                    setPlaceValue(e.target.value)
                }}
            />
            {ready && isFocused && (
                <div className="shadow-sm border position-absolute z-999 bg-light w-100">
                    {routes.map(({ name }, index) => {
                        return (
                            <button
                                key={index}
                                onClick={e => {
                                    e.preventDefault()
                                    setPlaceValue(name)
                                    onOptionCLick()
                                    setIsFocused(false)
                                }}
                                className={`btn btn-outline-primary p-3 w-100 border-top-0 border-end-0 border-start-0 ${
                                    index !== routes.length - 1
                                        ? "border-bottom"
                                        : "border-bottom-0"
                                } border-secondary rounded-0`}
                            >
                                <div className="text-start text-secondary">
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="location-icon">{Icons.location()}</div>
                                        <div className="linear-text">{name}</div>
                                    </div>
                                </div>
                            </button>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default PlacesAutoCompleteInput
