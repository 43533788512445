import React from "react"
import {
    BusCityTransfer,
    CarCityTransfer,
    CityTours,
    HistoryTours,
} from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"

const MiniServices = () => {
    const { t } = useTranslation()
    const Services = [
        {
            icon: CarCityTransfer,
            title: t("Inner city transfer"),
            description: t("We can help you with your safe and fast intracity travels."),
        },
        {
            icon: BusCityTransfer,
            title: t("Intercity transfer"),
            description: t(
                "We can gladly provide the service you are looking for when traveling from one city to another."
            ),
        },
        {
            icon: CityTours,
            title: t("Customized city tours"),
            description: t(
                "We can comfortably tour the places you want to go on your city tour, which you can arrange according to your own wishes."
            ),
        },
        {
            icon: HistoryTours,
            title: t("History & Nature Tours"),
            description: t(
                "We will be happy to help you in historical and natural tours in your city."
            ),
        },
    ]
    return (
        <div className="p-2 py-5 p-sm-5 bg-secondary">
            <div className="row m-0 my-2 my-sm-5">
                {Services.map(({ icon, title, description }, index) => {
                    return (
                        <div className="text-primary col-lg-3 col-12" key={index}>
                            <div
                                className={`bg-dark-gray p-3 py-3 py-lg-4 h-lg-100 ${
                                    index === 0
                                        ? "mini-service-item-first"
                                        : index === Services.length - 1
                                        ? "mini-service-item-last"
                                        : "mini-service-item"
                                }`}
                            >
                                <div className="d-flex d-lg-none align-items-center">
                                    <img src={icon} className="mini-service-icon" alt="Icon" />
                                    <div className="ps-2">{title}</div>
                                </div>
                                <img
                                    src={icon}
                                    className="mini-service-icon mb-2 d-none d-lg-block"
                                    alt="Icon"
                                />
                                <div className="d-none d-lg-block pt-3">{title}</div>
                                <div className="fs-12px fw-lighter mt-2 lh-200pr">
                                    {description}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MiniServices
