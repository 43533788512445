import React from "react"
import { useState } from "react"
import { Trans } from "react-i18next"
import { useApp } from "../../providers/AppProvider"
import { useTrack } from "../../providers/TrackProvider"

const OrderInformation = () => {
    // States and Hooks
    const [selectedTab, setSelectedTab] = useState(Tabs[0].value)

    // Render
    return (
        <div className="w-100 bg-white shadow-sm p-4 mt-3">
            <div className="order-information">
                <div className="d-flex align-items-center justify-content-start gap-3 gap-sm-3 border-bottom border-2 border-primary pb-2 mb-3 overflow-scroll scroll-hidden">
                    {Tabs.map(({ label, value }, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => setSelectedTab(value)}
                                className={`btn p-0 text-nowrap ${
                                    value === selectedTab && "fw-600 text-secondary"
                                }`}
                            >
                                <Trans>{label}</Trans>
                            </button>
                        )
                    })}
                </div>
                <div>{Tabs.find(item => item.value === selectedTab).content}</div>
            </div>
        </div>
    )
}

export default OrderInformation

const CustomerInformation = () => {
    // States and Hooks
    const { detailData } = useTrack()

    // Rendering
    return (
        <div className="row mx-0">
            <div className="col-6 mb-2 px-1">
                <div className="card rounded p-3">
                    <div className="fw-600">
                        <Trans>Full Name</Trans>
                    </div>
                    <div className="fw-400">
                        <Trans>
                            {detailData?.customer?.firstName + " " + detailData?.customer?.lastName}
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="col-6 mb-2 px-1">
                <div className="card rounded p-3">
                    <div className="fw-600">
                        <Trans>Email</Trans>
                    </div>
                    <div className="fw-400">
                        <Trans>{detailData?.customer?.email}</Trans>
                    </div>
                </div>
            </div>
            <div className="col-6 mb-2 px-1">
                <div className="card rounded p-3">
                    <div className="fw-600">
                        <Trans>Phone Number</Trans>
                    </div>
                    <div className="fw-400">
                        <Trans>{detailData?.customer?.phoneNumber}</Trans>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ExtrasContent = () => {
    // States and Hooks
    const { detailData } = useTrack()
    const {} = useApp()

    // Render
    return (
        <div className="row mx-0">
            {detailData?.extras?.map(item => {
                return (
                    <div className="col-6 mb-2 px-1" key={item.id}>
                        <div className="card rounded p-3">
                            <div className="fw-600">{item.defaultName}</div>
                            <div className="fw-400">
                                {item.price.toFixed(2) + " " + detailData.currencyType}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const Tabs = [
    {
        label: "Customer Info",
        value: "customer_info",
        content: <CustomerInformation />,
    },
    {
        label: "Extras",
        value: "extras",
        content: <ExtrasContent />,
    },
]
