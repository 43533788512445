import { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { useApi } from "./ApiProvider"

const Context = createContext()
export const useTrack = () => useContext(Context)

export const TrackProvider = ({ children }) => {
    // States and Hooks
    const { code } = useParams()
    const { reservations } = useApi()
    const [trackData, setTrackData] = useState(null)
    const [detailData, setDetailData] = useState(null)
    const [loading, setLoading] = useState(true)

    // Methods
    const trackReservation = async () => {
        const { data } = await reservations
            .track({ reservationId: code })
            .catch(error => console.log("Error"))
        setTrackData(data)
    }

    const reservationDetail = async () => {
        const { data } = await reservations.detail({ reservationId: code })
        setDetailData(data)
    }

    const initialize = async () => {
        setLoading(true)
        await trackReservation()
        await reservationDetail()
        setLoading(false)
    }

    useEffect(() => {
        initialize()
    }, [])

    // Binding
    const value = {
        // States
        loading,
        trackData,
        detailData,

        // Methods
        trackReservation,
    }

    // Render
    return <Context.Provider value={value}>{children}</Context.Provider>
}
