import React from "react"
import CompleteInformationForm from "../components/complete-information/CompleteInformationForm"
import { ProgressBarPaymentActive, ProgressBarPaymentActiveTr } from "../utilities/ImageImports"
import Layout from "../components/template/Layout"
import CompleteInformationFormProvider from "../providers/complete-information/CompleteInformationFormProvider"
import FeaturedPage from "../components/template/FeaturedPage"
import { useTranslation } from "react-i18next"
import { AppLangs } from "../components/home/LangSwitch"

const CompleteInformation = () => {
    // States and Hooks
    const { i18n } = useTranslation()

    // Render
    return (
        <Layout>
            <FeaturedPage>
                <div className="d-none d-sm-flex justify-content-center mb-3 mb-sm-5">
                    <img
                        src={
                            i18n.language === AppLangs.English
                                ? ProgressBarPaymentActive
                                : ProgressBarPaymentActiveTr
                        }
                        alt="progress-bar"
                        className="img-fluid col-8 col-md-7"
                    />
                </div>
                <CompleteInformationFormProvider>
                    <CompleteInformationForm />
                </CompleteInformationFormProvider>
            </FeaturedPage>
        </Layout>
    )
}

export default CompleteInformation
