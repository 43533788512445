import { useParams } from "react-router"
import { useApi } from "./ApiProvider"
import { sleep } from "../functions/sleep"
import { createContext, useContext, useEffect, useState } from "react"

const Context = createContext()
export const useFeedback = () => useContext(Context)

export const FeedbackProvider = ({ children }) => {
    // States and Hooks
    const { code } = useParams()
    const { reservations } = useApi()
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [questions, setQuestions] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isDone, setIsDone] = useState(false)

    // Methods
    const fetchReservationFeedbackQuestions = async () => {
        const { data } = await reservations.feedback({ reservationId: code })
        setQuestions(data?.questions)
    }

    const initialize = async () => {
        setLoading(true)
        await fetchReservationFeedbackQuestions()
        setLoading(false)
    }

    const onSubmitButtonClick = async () => {
        setIsSubmitLoading(true)
        await sleep(3000)
        setIsDone(true)
        setIsSubmitLoading(false)
    }

    useEffect(() => {
        initialize()
    }, [])

    // Binding
    const value = {
        // States
        loading,
        isSubmitLoading,
        questions,
        isDone,

        // Methods
        onSubmitButtonClick,
    }

    // Render
    return <Context.Provider value={value}>{children}</Context.Provider>
}
