import React from "react"
import Layout from "../components/template/Layout"
import { useTrack } from "../providers/TrackProvider"
import FeaturedPage from "../components/template/FeaturedPage"
import TrackingMap from "../components/order-tracking/TrackingMap"
import OrderInformation from "../components/order-tracking/OrderInformation"
import DriverInformation from "../components/order-tracking/DriverInformation"
import Loader from "../components/template/Loader"
import ErrorBox from "../components/template/ErrorBox"
import { useTranslation } from "react-i18next"

const OrderTracking = () => {
    // Methods
    const { t } = useTranslation()
    const { loading, detailData } = useTrack()

    // Methods

    // Render
    return (
        <Layout>
            <FeaturedPage>
                {loading && (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center bg-white">
                        <Loader dark />
                    </div>
                )}
                {!loading && (
                    <div className="col-12">
                        <div className="row m-0">
                            {!detailData && (
                                <div className="py-5 my-5">
                                    <ErrorBox
                                        error={t("Reservation not found")}
                                        className="text-center d-flex justify-content-center align-items-center"
                                    />
                                </div>
                            )}
                            {detailData && (
                                <>
                                    <div className="col-12 col-xl-5">
                                        <DriverInformation />
                                        <OrderInformation />
                                    </div>
                                    <div className="col-12 col-xl-7  mt-3 mt-lg-0">
                                        <TrackingMap />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </FeaturedPage>
        </Layout>
    )
}

export default OrderTracking
