import React from "react"
import { Link } from "react-router-dom"
import {
    Logo,
    Tursab,
    Geo,
    Telephone,
    Envelope,
    Facebook,
    Instagram,
    Twitter,
    FooterBackground,
} from "../../utilities/ImageImports"
import FooterContactItem from "../template/FooterContactItem"
import { Trans, useTranslation } from "react-i18next"
import { AppRoutes } from "../../utilities/AppRoutes"
import { CompanyPhoneNumber } from "../../utilities/Config"

const Footer = () => {
    // States and Hooks
    const { t } = useTranslation()

    // Render
    return (
        <div
            className="bg-footer"
            style={{
                backgroundImage: `url(${FooterBackground})`,
            }}
        >
            <div className="row m-0 p-0 pt-2 border-bottom-thick"></div>
            <div className="row text-white m-0 py-4 px-side">
                <div className="col-12 col-md-4 p-0 pb-3 pb-md-0">
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center text-md-start">
                            <img
                                src={Logo}
                                alt="logo"
                                className="img-fluid col-6 col-sm-4 col-md-6 text-white pb-4 m-0"
                                style={{
                                    filter: "invert(100%) brightness(2)",
                                }}
                            />
                        </div>
                        <p className="fs-16px fw-lighter lh-32px footer-text m-0 pb-4 lh-200pr text-center">
                            <Trans>
                                Transferix Transfer is always with you for your transfer needs with
                                its friendly, professional and expert staff
                            </Trans>
                        </p>
                        <img
                            src={Tursab}
                            alt="tursab"
                            className="d-none d-md-block img-fluid col-6 mx-auto m-md-0"
                        />
                    </div>
                </div>
                <div className="d-none d-md-block col-12 col-md-5 p-0 pb-5 pb-md-0 px-md-0 text-center">
                    <div className="d-flex h-100 flex-column justify-content-start gap-3">
                        <p className="fs-16px fw-700 m-0 pb-3 pb-md-0 text-primary">
                            <span className="pe-3">&#8212;&#8212;&#8212;</span>
                            <Trans>Hot Links</Trans>
                            <span className="ps-3">&#8212;&#8212;&#8212;</span>
                        </p>
                        <Link to={AppRoutes.home}>
                            <p className="footer-link ls-5p fw-lighter pb-3 pb-md-0">
                                <Trans>Home</Trans>
                            </p>
                        </Link>
                        <Link to={AppRoutes.aboutUs}>
                            <p className="footer-link ls-5p fw-lighter pb-3 pb-md-0">
                                <Trans>About Us</Trans>
                            </p>
                        </Link>
                        <Link to={AppRoutes.services}>
                            <p className="footer-link ls-5p fw-lighter pb-3 pb-md-0">
                                <Trans>Services</Trans>
                            </p>
                        </Link>
                        <Link to={AppRoutes.contact}>
                            <p className="footer-link ls-5p fw-lighter pb-3 pb-md-0">
                                <Trans>Contact</Trans>
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-md-3 p-0">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className="pb-4">
                                <FooterContactItem
                                    icon={Geo}
                                    title={t("Our Address")}
                                    description="34 Karaoğlanoğlu Caddesi Karaoğlanoğlu, Kyrenia 9510"
                                ></FooterContactItem>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-12 p-0">
                            <div className="pb-4">
                                <FooterContactItem
                                    icon={Telephone}
                                    title={t("Phone")}
                                    description={CompanyPhoneNumber}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-12 p-0">
                            <div className="pb-0">
                                <FooterContactItem
                                    icon={Envelope}
                                    title={t("Email")}
                                    description="support@cyprobus.com"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-md-none m-0 mb-5">
                <img src={Tursab} alt="tursab" className="img-fluid col-6 mx-auto m-md-0" />
            </div>
            <div className="row p-0 border-bottom-thin mx-side"></div>
            <div className="row text-white m-0 py-3 px-side border-bottom-thin">
                <div className="d-flex p-0 align-items-center">
                    <p className="d-none d-md-block fs-16 fw-lighter footer-text m-0 pe-4">
                        <Trans>Follow us</Trans> :
                    </p>
                    <a href="https://www.facebook.com/CyproBus" target="_blank">
                        <img src={Facebook} alt="facebook" className="img-fluid pe-2 pe-sm-4" />
                    </a>
                    <a href="https://www.instagram.com/cypro_bus" target="_blank">
                        <img src={Instagram} alt="instagram" className="img-fluid" />
                    </a>
                    <h6 className="fw-lighter footer-text ps-3 ps-sm-0 m-0 ms-auto">
                        <Trans>Copyright is reserved</Trans>
                    </h6>
                </div>
            </div>
        </div>
    )
}

export default Footer
