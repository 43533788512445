import { useSearchParams } from "react-router-dom"
import React, { useEffect, useRef, useState } from "react"
import Loader from "../components/template/Loader"

const PaymentMobile = () => {
    // States and Hooks
    const [loading, setLoading] = useState(true)
    const [search] = useSearchParams()
    const paymentButtonRef = useRef()
    const cardnumber = search.get("cardnumber")
    const cardexpiredatemonth = search.get("cardexpiredatemonth")
    const cardexpiredateyear = search.get("cardexpiredateyear")
    const cardcvv2 = search.get("cardcvv2")
    const mode = search.get("mode")
    const secure3dsecuritylevel = search.get("secure3dsecuritylevel")
    const apiversion = search.get("apiversion")
    const terminalprovuserid = search.get("terminalprovuserid")
    const terminaluserid = search.get("terminaluserid")
    const terminalmerchantid = search.get("terminalmerchantid")
    const txntype = search.get("txntype")
    const txnamount = search.get("txnamount")
    const txncurrencycode = search.get("txncurrencycode")
    const txninstallmentcount = search.get("txninstallmentcount")
    const orderid = search.get("orderid")
    const terminalid = search.get("terminalid")
    const successurl = search.get("successurl")
    const errorurl = search.get("errorurl")
    const customeremailaddress = search.get("customeremailaddress")
    const customeripaddress = search.get("customeripaddress")
    const secure3dhash = search.get("secure3dhash")

    // Methods
    const handlePayment = () => paymentButtonRef.current.click()

    // UseEffects
    useEffect(() => {
        if (!loading) handlePayment()
    }, [loading])

    useEffect(() => {
        setTimeout(() => setLoading(false), 2_000)
    }, [])

    // Render
    if (loading)
        return (
            <div className="vh-100 d-flex align-items-center justify-content-center bg-white">
                <Loader dark />
            </div>
        )
    return (
        <form
            action="https://sanalposprov.garanti.com.tr/servlet/gt3dengine"
            method="POST"
            className="d-none"
        >
            <input name="cardnumber" value={cardnumber} />
            <br />
            <input name="cardexpiredatemonth" value={cardexpiredatemonth} />
            <br />
            <input name="cardexpiredateyear" value={cardexpiredateyear} />
            <br />
            <input name="cardcvv2" value={cardcvv2} />
            <br />
            <input name="mode" value={mode} />
            <br />
            <input name="secure3dsecuritylevel" value={secure3dsecuritylevel} />
            <br />
            <input name="apiversion" value={apiversion} />
            <br />
            <input name="terminalprovuserid" value={terminalprovuserid} />
            <br />
            <input name="terminaluserid" value={terminaluserid} />
            <br />
            <input name="terminalmerchantid" value={terminalmerchantid} />
            <br />
            <input name="txntype" value={txntype} />
            <br />
            <input name="txnamount" value={txnamount} />
            <br />
            <input name="txncurrencycode" value={txncurrencycode} />
            <br />
            <input name="txninstallmentcount" value={txninstallmentcount} />
            <br />
            <input name="orderid" value={orderid} />
            <br />
            <input name="terminalid" value={terminalid} />
            <br />
            <input name="successurl" value={successurl} />
            <br />
            <input name="errorurl" value={errorurl} />
            <br />
            <input name="customeremailaddress" value={customeremailaddress} />
            <br />
            <input name="customeripaddress" value={customeripaddress} />
            <br />
            <input name="secure3dhash" value={secure3dhash} />
            <br />
            <button ref={paymentButtonRef} type="submit">
                PAY
            </button>
            <br />
        </form>
    )
}

export default PaymentMobile
