import React, { useState, createContext, useContext, useEffect } from "react"
import { OneWayTripFormSchema, RoundTripFormSchema } from "../../utilities/FormValidations"
import { useNavigate } from "react-router"
import { AppRoutes } from "../../utilities/AppRoutes"
import { useApp } from "../AppProvider"
import usePlacesAutocomplete from "use-places-autocomplete"

const HomeContext = createContext()
export const useHome = () => useContext(HomeContext)

const HomeProvider = ({ children }) => {
    // States and Hooks
    const {
        TripTypes,
        currentTripType,
        fromValue,
        whereValue,
        whenValue,
        peopleValue,
        returnValue,
        searchTrips,
        setWhereValue,
        setFromValue,
        setSearchItems,
        clearTripSelectForm,
    } = useApp()

    // const { setSearchTripsErrors, setShowTripSelect } = useSearch()
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const [searchLoading, setSearchLoading] = useState(false)

    // Methods
    const getFormSchema = () => {
        return currentTripType === TripTypes.RoundTrip ? RoundTripFormSchema : OneWayTripFormSchema
    }

    const {
        suggestions: { status: fromStatus, data: fromSuggestions },
        setValue: setPlacesFromValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: "tr" },
        },
    })
    const {
        suggestions: { status: whereStatus, data: whereSuggestions },
        setValue: setPlacesWhereValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: "tr" },
        },
    })

    const onBookNowButtonClick = item => {
        setPlacesFromValue(item.from)
        setFromValue(item.from)
        setPlacesWhereValue(item.to)
        setWhereValue(item.to)
        document.getElementById("reservation-form").scrollIntoView()
    }

    useEffect(() => {
        if (fromStatus === "OK") {
            setFromValue(fromSuggestions[0].description)
            console.log(fromSuggestions[0].description)
        }
        if (whereStatus === "OK") {
            setWhereValue(whereSuggestions[0].description)
            console.log(whereSuggestions[0].description)
        }
    }, [whereSuggestions, fromSuggestions, fromStatus, whereStatus])

    const onSearchButtonClick = async e => {
        e.preventDefault()
        localStorage.clear()
        setError("")
        clearTripSelectForm()
        // setSearchTripsErrors("")
        document.getElementById("reservation-form").scrollIntoView()
        const validationObject = {
            from: fromValue,
            where: whereValue,
            when: whenValue,
            people: {
                adult: peopleValue.adultCount,
                children: peopleValue.childrenCount,
                infant: peopleValue.infantCount,
            },
        }

        if (currentTripType === TripTypes.RoundTrip) validationObject.return = returnValue

        setSearchLoading(true)
        const validation = await getFormSchema()
            .validate(validationObject)
            .catch(error => {
                setError(error.message)
            })
        if (validation) {
            const response = await searchTrips()
            if (response.error) {
                setError("Something went wrong")
            }

            if (response.data) {
                setSearchItems(response.data)
                navigate(AppRoutes.search)
            }
        }
        setSearchLoading(false)
    }

    // Binding
    const valueObject = {
        // States
        error,
        searchLoading,

        // Methods
        onSearchButtonClick,
        onBookNowButtonClick,
    }

    // Render
    return <HomeContext.Provider value={valueObject}>{children}</HomeContext.Provider>
}

export default HomeProvider
