import axios from "axios"

export const getUserCurrencyByIp = async () => {
    // const { REACT_APP_IP_API_KEY } = process.env
    try {
        // const { data } = await axios.get(`https://api.db-ip.com/v2/${REACT_APP_IP_API_KEY}/self`)
        const { data } = await axios.get(
            `https://ipapi.co/json?random=${Math.ceil(Math.random() * 100000000)}`
        )
        // const { data } = await axios.get(
        //     `https://api.db-ip.com/v2/${REACT_APP_IP_API_KEY}/self?random=${Math.ceil(
        //         Math.random() * 100000000
        //     )}`,
        // )
        if (data)
            return {
                error: null,
                data,
            }
        return {
            error: "Something went wrong",
            data: null,
        }
    } catch (error) {
        return {
            error: "Something went wrong",
            data: null,
        }
    }
}

// This response is for the TR people with TR ip of course, pasting this here for later references:
// {
//     ip: "88.255.225.10",
//     version: "IPv4",
//     city: "Istanbul",
//     region: "Istanbul",
//     region_code: "34",
//     country: "TR",
//     country_name: "Turkey",
//     country_code: "TR",
//     country_code_iso3: "TUR",
//     country_capital: "Ankara",
//     country_tld: ".tr",
//     continent_code: "AS",
//     in_eu: false,
//     postal: "34758",
//     latitude: 41.0287,
//     longitude: 29.1172,
//     timezone: "Europe/Istanbul",
//     utc_offset: "+0300",
//     country_calling_code: "+90",
//     currency: "TRY",
//     currency_name: "Turkish Lira",
//     languages: "tr-TR,ku,diq,az,av",
//     country_area: 783562,
//     country_population: 84339067,
//     asn: "AS9121",
//     org: "Turk Telekomunikasyon Anonim Sirketi",
// },
