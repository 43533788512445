import React, { useContext, useEffect, useState } from "react"

export const HeaderContext = React.createContext()
export const useHeader = () => useContext(HeaderContext)

const HeaderProvider = ({ children }) => {
    // States and Hooks
    const menuToggleAnimationDuration = 200
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isMenuOpenDuplicate, setIsMenuOpenDuplicate] = useState(false)
    const [isOrderTrackingModalOpen, setIsOrderTrackingModalOpen] =
        useState(false)

    // Methods
    const openMenu = () => {
        const gap = document.querySelector(".header-menu-mobile-gap")
        gap.classList.remove("d-none")
        setIsMenuOpenDuplicate(true)
        setIsMenuOpen(true)
    }

    const closeMenu = () => {
        const gap = document.querySelector(".header-menu-mobile-gap")
        gap.classList.add("d-none")
        setIsMenuOpenDuplicate(false)
        setTimeout(() => setIsMenuOpen(false), menuToggleAnimationDuration)
    }

    const openOrderTrackingModal = () => setIsOrderTrackingModalOpen(true)
    const closeOrderTrackingModal = () => setIsOrderTrackingModalOpen(false)

    // UseEffects
    useEffect(() => {
        if (!isMenuOpenDuplicate) {
            setTimeout(() => {
                const item = document.querySelector(".header-menu-mobile")
                if (item) item.style.left = "-100%"
            }, menuToggleAnimationDuration)
        }
    }, [isMenuOpenDuplicate])

    // Binding
    const valueObject = {
        // States
        isMenuOpen,
        setIsMenuOpen,
        isMenuOpenDuplicate,
        setIsMenuOpenDuplicate,
        isOrderTrackingModalOpen,
        setIsOrderTrackingModalOpen,

        // Methods
        openMenu,
        closeMenu,
        openOrderTrackingModal,
        closeOrderTrackingModal,
    }

    // Render
    return (
        <HeaderContext.Provider value={valueObject}>
            {children}
        </HeaderContext.Provider>
    )
}

export default HeaderProvider
