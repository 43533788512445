import React from "react"

const FormTitle = ({ children }) => {
    return (
        <p className="fs-20px fw-600 text-secondary p-0 m-0 mb-3 pb-3 form-title-border">
            {children}
        </p>
    )
}

export default FormTitle
