export const AppRoutes = {
    home: "/",
    completeInformation: "/complete-information",
    aboutUs: "/about-us",
    services: "/services",
    contact: "/contact",
    history: "/history",
    search: "/search",
    paymentSuccess: "/payment-success",
    paymentFailure: "/payment-failure",
    paymentStatus: "/payment-status",
    paymentMobile: "/payment-mobile",
    orderTracking: "/order-tracking",
    feedback: "/feedback",
}

export const RoutesWithoutAppDependency = [
    AppRoutes.paymentStatus,
    AppRoutes.paymentFailure,
    AppRoutes.paymentSuccess,
]
