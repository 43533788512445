import React from "react"
import PeopleSelect from "../complete-information/PeopleSelect"
import MobileDateTimePickerField from "../complete-information/MobileDateTimePickerField"
import DesktopDateTimePickerField from "../template/DesktopDateTimePickerField"
import PlacesAutoCompleteInput from "../complete-information/PlacesAutoCompleteInput"

import {
    ProgressBarSearchActive,
    Calendar,
    AddCircleAlt,
    ProgressBarSearchActiveTr,
} from "../../utilities/ImageImports"
import "react-day-picker/dist/style.css"
import { Trans, useTranslation } from "react-i18next"
import { useHome } from "../../providers/home/HomeProvider"
import Loader from "../template/Loader"
import { MIN_OFFSET_FROM_START_RESERVATION, useApp } from "../../providers/AppProvider"
import ErrorBox from "../template/ErrorBox"
import { AppLangs } from "./LangSwitch"

const ReservationForm = () => {
    // States and Hooks
    const { i18n, t } = useTranslation()
    const {
        TripTypes,
        routes,
        currentTripType,
        setCurrentTripType,
        whenValue,
        returnValue,
        fromValue,
        whereValue,
        peopleValue,
        setWhenValue,
        setFromValue,
        setWhereValue,
        setReturnValue,
        setPeopleValue,
        clearTripSelectForm,
        setSearchItems,
        setShowTripSelect,
        unavailableDatesItems,
    } = useApp()
    const {
        error,
        searchLoading,

        onSearchButtonClick,
    } = useHome()
    // The return value should have at least 3 hours offset from the when value.
    // so that the client doesn't just select 1 minute after the reservation.
    // This requirement is coming from the client.
    const newWhenValueIncludingOffset = new Date(
        new Date(whenValue).getTime() + MIN_OFFSET_FROM_START_RESERVATION
    )

    // Render
    return (
        <div className="row m-0 px-lg-3">
            <div className="col-lg-6 col-12">
                <form className="bg-white mt-3 p-4 scroll-mt" id="reservation-form">
                    <div className="w-100">
                        <div className="text-center mx-auto">
                            <img
                                src={
                                    i18n.language === AppLangs.English
                                        ? ProgressBarSearchActive
                                        : ProgressBarSearchActiveTr
                                }
                                className="img-fluid mb-4 d-none d-lg-inline-block"
                                alt="Progress bar search active icon"
                            />
                        </div>
                        <div className="row m-0 gap-1 gap-xl-0">
                            <div className="col-xl-6 col-12 ps-xl-0 pe-xl-1 px-0">
                                <button
                                    className={`btn rounded-0 w-100 py-3 fw-600 ${
                                        currentTripType === TripTypes.RoundTrip
                                            ? "home-hero-btn-secondary"
                                            : "home-hero-btn-primary"
                                    }`}
                                    onClick={e => {
                                        e.preventDefault()
                                        setCurrentTripType(TripTypes.RoundTrip)
                                    }}
                                >
                                    <Trans>{TripTypes.RoundTrip}</Trans>
                                </button>
                            </div>

                            <div className="col-xl-6 col-12 pe-xl-0 ps-xl-1 px-0">
                                <button
                                    className={`btn rounded-0 w-100 py-3 fw-600 ${
                                        currentTripType === TripTypes.OneWay
                                            ? "home-hero-btn-secondary"
                                            : "home-hero-btn-primary"
                                    }`}
                                    onClick={e => {
                                        e.preventDefault()
                                        setCurrentTripType(TripTypes.OneWay)
                                    }}
                                >
                                    <Trans>{TripTypes.OneWay}</Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-3">
                        <ErrorBox error={error} />
                        <div className="col-xl-6 col-12 ps-xl-0 pe-xl-1 px-0 mb-2">
                            <PlacesAutoCompleteInput
                                placeValue={fromValue}
                                setPlaceValue={setFromValue}
                                placeholder={`${t("From")}: `}
                                routes={routes}
                                clearOnChange={() => {
                                    clearTripSelectForm()
                                    setSearchItems(null)
                                    setShowTripSelect(false)
                                }}
                            />
                        </div>
                        <div className="col-xl-6 col-12 pe-xl-0 ps-xl-1 px-0 mb-2">
                            <PlacesAutoCompleteInput
                                placeValue={whereValue}
                                setPlaceValue={setWhereValue}
                                placeholder={`${t("Where")}: `}
                                routes={routes}
                                clearOnChange={() => {
                                    clearTripSelectForm()
                                    setSearchItems(null)
                                    setShowTripSelect(false)
                                }}
                            />
                        </div>
                        <div className="col-xl-6 col-12 ps-xl-0 pe-xl-1 px-0 mb-2">
                            <div className="d-flex d-md-none align-items-center">
                                <MobileDateTimePickerField
                                    placeholder={`${t("When")}: `}
                                    icon={Calendar}
                                    minDate={new Date()}
                                    maxDate={returnValue ? returnValue : undefined}
                                    date={whenValue}
                                    setDateTime={newValue => {
                                        if (newValue !== whenValue) {
                                            clearTripSelectForm()
                                            setSearchItems(null)
                                            setShowTripSelect(false)
                                            setWhenValue(newValue)
                                        }
                                    }}
                                    hideIconOnSelected={true}
                                    unavailableDates={unavailableDatesItems}
                                />
                            </div>
                            <div className="d-none d-md-block">
                                <DesktopDateTimePickerField
                                    dateTime={whenValue}
                                    setDateTime={newValue => {
                                        if (newValue !== whenValue) {
                                            clearTripSelectForm()
                                            setSearchItems(null)
                                            setShowTripSelect(false)
                                            setWhenValue(newValue)
                                        }
                                    }}
                                    icon={Calendar}
                                    placeholder={`${t("When")}: `}
                                    minDate={new Date()}
                                    maxDate={returnValue ? returnValue : undefined}
                                    unavailableDates={unavailableDatesItems}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-12 pe-xl-0 ps-xl-1 px-0 mb-2">
                            <div
                                className={`d-flex d-md-none align-items-center ${
                                    currentTripType === TripTypes.OneWay && "opacity-50"
                                }`}
                            >
                                <MobileDateTimePickerField
                                    placeholder={`${t("Return")}: `}
                                    icon={AddCircleAlt}
                                    minDate={whenValue ? newWhenValueIncludingOffset : new Date()}
                                    maxDate={undefined}
                                    date={returnValue}
                                    setDateTime={newValue => {
                                        if (newValue !== returnValue) {
                                            clearTripSelectForm()
                                            setSearchItems(null)
                                            setShowTripSelect(false)
                                            setReturnValue(newValue)
                                        }
                                    }}
                                    hideIconOnSelected={true}
                                    unavailableDates={unavailableDatesItems}
                                    disabled={currentTripType === TripTypes.OneWay}
                                />
                            </div>
                            <div className="d-none d-md-block">
                                <DesktopDateTimePickerField
                                    dateTime={returnValue}
                                    setDateTime={newValue => {
                                        if (newValue !== returnValue) {
                                            clearTripSelectForm()
                                            setSearchItems(null)
                                            setShowTripSelect(false)
                                            setReturnValue(newValue)
                                        }
                                    }}
                                    icon={AddCircleAlt}
                                    placeholder={`${t("Return")}: `}
                                    minDate={whenValue ? newWhenValueIncludingOffset : new Date()}
                                    maxDate={undefined}
                                    unavailableDates={unavailableDatesItems}
                                    disabled={currentTripType === TripTypes.OneWay}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-xl-6 col-12 ps-xl-0 pe-xl-1 px-0">
                            <PeopleSelect
                                shortFormClassName="d-block d-sm-none d-xl-block"
                                longFormClassName="d-none d-sm-block d-xl-none"
                                setPeople={setPeopleValue}
                                people={peopleValue}
                            />
                        </div>
                        <div className="col-xl-6 col-12 pe-xl-0 ps-xl-1 px-0 mt-2 mt-xl-0">
                            <button
                                type="submit"
                                className="btn home-hero-btn-secondary rounded-0 w-100 h-100 py-custom fw-600"
                                disabled={searchLoading}
                                onClick={onSearchButtonClick}
                            >
                                {searchLoading ? (
                                    <Loader />
                                ) : (
                                    <div>
                                        <Trans>Search</Trans>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ReservationForm
