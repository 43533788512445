import React, { useState } from "react"
import { NewsLetterBackground } from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"

const OurServices = () => {
    const { t } = useTranslation()
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    const TabsContent = [
        {
            title: t("Inner City Transfer"),
            content: (
                <>
                    <div className="mb-4">
                        <Trans>
                            We offer safe, affordable and fast transfer options for your inner city
                            journeys.
                        </Trans>
                    </div>
                    <div>
                        <Trans>
                            With our city transfer service, you will never be late for your city
                            trips. We are at your side with our best drivers, most comfortable
                            vehicles and affordable options to make your journeys easier.
                        </Trans>
                    </div>
                </>
            ),
        },
        {
            title: t("Intercity Transfer"),
            content: (
                <>
                    <div className="mb-4">
                        <Trans>
                            We provide fast and safe transportation with our best vehicles and most
                            experienced drivers for your intercity journeys.
                        </Trans>
                    </div>
                    <div>
                        <Trans>
                            With our intercity transfer service, you can now have a safe and
                            comfortable travel experience on your intercity journeys. We are at your
                            side with our best vehicles, most experienced drivers and affordable
                            options, in which we apply international hygiene standards.
                        </Trans>
                    </div>
                </>
            ),
        },
        {
            title: t("Customized City Tours"),
            content: (
                <>
                    <div className="mb-4">
                        <Trans>
                            In Antalya, Turkey's tourism center, we provide your transportation with
                            the most comfortable vehicles between the routes that will be determined
                            by you according to your preferences.
                        </Trans>
                    </div>
                    <div className="mb-4">
                        <Trans>
                            In Antalya, which is the right city for a fascinating culture, nature
                            and history-filled tour experience, we can plan a comfortable and
                            enjoyable tour for you after learning your preferences about the places
                            you want to go. You can add exquisite natural wonders such as Düden
                            Waterfall and Karpuzkaldıran Waterfall to your historical route such as
                            Perge Ancient City, Aspendos Ancient City, Three Gates, Kaleiçi, Yivli
                            Minaret.
                        </Trans>
                    </div>
                    <div>
                        <Trans>
                            With our tour program specially prepared for you, you will discover the
                            most beautiful spots of the city, hidden places, local food and culture.
                            Our experienced guides will accompany you throughout this exploration.
                        </Trans>
                    </div>
                </>
            ),
        },
        {
            title: t("History & Nature Tours"),
            content: (
                <>
                    <div className="mb-4">
                        <Trans>
                            We offer you the undiscovered places, historical and cultural heritages
                            of the cities with a special route in the history and nature tours
                            customized according to your interests.
                        </Trans>
                    </div>
                    <div>
                        <Trans>
                            You can complete your tour safely and without getting tired with our
                            comfortable vehicles on the routes over the rich history and
                            breathtaking natural beauties. If you wish, our experienced guides can
                            accompany your tour with special explanations.
                        </Trans>
                    </div>
                </>
            ),
        },
    ]

    return (
        <>
            <div className="bg-white">
                <div className="text-center py-5">
                    <div className="h2 fw-bold mb-0 my-sm-5">
                        <Trans>Our Services</Trans>
                    </div>
                </div>
            </div>
            <div className="tab-section px-2 px-sm-0">
                <div
                    className="tab-section py-5 px-0 px-sm-4"
                    style={{
                        backgroundImage: `url(${NewsLetterBackground})`,
                    }}
                >
                    <div className="col-lg-11 col-11 mx-auto h-100">
                        <div className="h-100 d-flex align-items-center justify-content-start flex-column">
                            <div className="w-100 d-flex align-items-center justify-content-between gap-3 pb-4 auto-fill-list">
                                {TabsContent.map(({ title, content }, index) => {
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentTabIndex(index)}
                                            className={`d-inline-block btn rounded-0 p-0 pb-2 ${
                                                currentTabIndex === index
                                                    ? "text-primary fw-500 active-tab"
                                                    : "text-light fw-light"
                                            }`}
                                        >
                                            {title}
                                        </button>
                                    )
                                })}
                            </div>
                            <div className="text-light fw-light fs-14px text-justify p-0 mt-sm-3 mt-5 lh-200pr">
                                {TabsContent[currentTabIndex].content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurServices
