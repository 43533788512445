import React from "react"
import { Trans } from "react-i18next"
import { AboutUsCreditBackground } from "../../utilities/ImageImports"

const Credit = () => {
    return (
        <div className="mt-n pb-5">
            <div className="credit-title px-2 pb-4 pb-sm-5 fw-700 text-center m-0 lh-172pr">
                <Trans>Why should you choose CyproBus?</Trans>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-evenly px-3 px-lg-5 credit-value-container">
                <div className="text-center px-sm-3 pb-4 pb-sm-0">
                    <div className="credit-value fw-700 ls-5p">100%</div>
                    <div className="h5 fw-400 m-0 lh-133pr">
                        <Trans>Customer satisfaction</Trans>
                    </div>
                </div>
                <div
                    className="credit-bg text-center px-sm-3 pb-4 pb-sm-0"
                    style={{
                        backgroundImage: `url(${AboutUsCreditBackground})`,
                    }}
                >
                    <div className="credit-value fw-700 ls-5p">7/24</div>
                    <div className="h5 fw-400 m-0 lh-133pr">
                        <Trans>Our activity history</Trans>
                    </div>
                </div>
                <div className="text-center px-sm-3">
                    <div className="credit-value fw-700  ls-5p">$€₺</div>
                    <div className="h5 fw-400 m-0 lh-133pr">
                        <Trans>Services performed</Trans>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Credit
