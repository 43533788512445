import React from "react"

const Loader = ({ dark }) => {
    return (
        <div className="w-100 d-flex align-items-center justify-content-center">
            <div className={`loader ${dark ? "loader-dark" : "loader-light"}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className="invisible">Loading</span>
            </div>
        </div>
    )
}

export default Loader
