import React, { useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Icons } from "../../utilities/Icons"
import { AppRoutes } from "../../utilities/AppRoutes"
import { Logo, BgPaymentSuccess } from "../../utilities/ImageImports"
import Loader from "../template/Loader"

const PaymentSuccess = () => {
    // States and Hooks
    const navigate = useNavigate()
    const [isClicked, setIsClicked] = useState(false)
    const isLocalStorageValid =
        localStorage.getItem("prevSearchObject") &&
        localStorage.getItem("appObject") &&
        localStorage.getItem("prevReservationData")
    const reservationDetails = JSON.parse(localStorage.getItem("prevReservationData"))
    const searchDetails = JSON.parse(localStorage.getItem("prevSearchObject"))

    // Methods
    const copyToClipboard = text => {
        navigator.clipboard
            .writeText(text)
            .catch(error => console.error(`Error copying text: ${error}`))
    }

    // Render
    return (
        <>
            <div className="row m-0 justify-content-center px-3 px-sm-0">
                <div className="col-12 col-sm-8 col-lg-6 bg-white p-0 payment-card">
                    <div
                        className="text-center payment-card-title position-relative py-4"
                        style={{
                            backgroundImage: `url(${BgPaymentSuccess})`,
                            backgroundPosition: "0px -75px",
                        }}
                    >
                        <div className="col-6 col-sm-4 mx-auto">
                            <img src={Logo} alt="logo" className="img-fluid py-4" />
                        </div>
                        <div className="payment-status-icon">{Icons.successTickCircle()}</div>
                    </div>

                    <div className="d-flex flex-column payment-card-description gap-4 pb-5">
                        <div className="h4 m-0 fw-600 text-success text-center">
                            <Trans>Payment Successful</Trans>
                        </div>
                        {isLocalStorageValid && (
                            <div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>Full Name</Trans>
                                    </div>
                                    <div>
                                        {reservationDetails.firstName +
                                            " " +
                                            reservationDetails.lastName}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>Email</Trans>
                                    </div>
                                    <div>{reservationDetails.email}</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>Session ID</Trans>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        {reservationDetails.sessionId.slice(0, 7) +
                                            "..." +
                                            reservationDetails.sessionId.slice(
                                                reservationDetails.sessionId.length - 7,
                                                reservationDetails.sessionId.length
                                            )}
                                        <button
                                            className="btn bg-gray p-0"
                                            onClick={() =>
                                                copyToClipboard(reservationDetails.sessionId)
                                            }
                                        >
                                            {Icons.copy()}
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>From</Trans>
                                    </div>
                                    <div className="text-truncate w-75 text-end">
                                        {searchDetails.originName}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>Where</Trans>
                                    </div>
                                    <div className="text-truncate w-75 text-end">
                                        {searchDetails.destinationName}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between border-1 border-bottom border-gray py-3">
                                    <div className="fw-600">
                                        <Trans>Trip type</Trans>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <Trans>
                                            {searchDetails.returnDate === null
                                                ? "One Way"
                                                : "Round Trip"}
                                        </Trans>
                                    </div>
                                </div>
                            </div>
                        )}
                        <button
                            disabled={isClicked}
                            onClick={() => {
                                setIsClicked(true)
                                window?.ReactNativeWebView?.postMessage("BackToHomeScreen")
                                setTimeout(() => {
                                    navigate(AppRoutes.home)
                                }, 2000)
                            }}
                            className="btn btn-secondary rounded-0 fw-500 py-3"
                        >
                            {isClicked ? <Loader /> : <Trans>Back to Home Page</Trans>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess

function stringFormat(input) {
    return input.slice(0, 7) + "..." + input.slice(input.length - 7, input.length)
}
