import React from "react"
import {
    Aspendos,
    Karain,
    Phaselis,
    ArrowRightAlt,
} from "../../utilities/ImageImports"

const Tours = () => {
    const tours = [
        { id: 1, image: Aspendos, name: "ASPENDOS - SİDE" },
        { id: 2, image: Karain, name: "KARAİN - TERMESSOS" },
        { id: 3, image: Phaselis, name: "PHASELIS - OLYMPOS" },
        { id: 4, image: Aspendos, name: "ASPENDOS - SİDE" },
        { id: 5, image: Karain, name: "KARAİN - TERMESSOS" },
        { id: 6, image: Phaselis, name: "PHASELIS - OLYMPOS" },
    ]
    return (
        <div className="row m-0 py-5 px-4 px-sm-5">
            {tours.map(tour => {
                return (
                    <div
                        className="col-12 col-md-6 col-lg-4 position-relative px-3 py-1 p-sm-3"
                        key={tour.id}
                    >
                        <img
                            src={tour.image}
                            alt={tour.name}
                            className="w-100 tour-image p-2 px-0 px-sm-2"
                        />
                        <button className="overlay-button text-white px-5 w-100">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="h5 m-0 text-start tour-name">
                                    {tour.name}
                                </div>
                                <span>
                                    <img
                                        src={ArrowRightAlt}
                                        alt="arrow-right"
                                        className="small-icon"
                                    />
                                </span>
                            </div>
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default Tours
