import React from "react"
import { Trans } from "react-i18next"

const Credit = () => {
    return (
        <div className="credit p-lg-5 py-5">
            <div className="row m-0 p-0 align-items-center">
                <div className="col-lg-5 col-12 h2 fw-bold text-lg-start text-center px-3 py-0 px-sm-4 py-lg-0 ls-m5p">
                    <Trans>Why should you choose Transferix?</Trans>
                </div>
                <div className="col-lg-7 col-12 credit-numbers py-3 px-3 px-sm-5 px-lg-1">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row gap-2 gap-sm-4">
                        <div className="text-center">
                            <div className="credit-title fw-bold ls-m5p">100%</div>
                            <div className="credit-description px-2 fw-500 ls-m5p">
                                <Trans>Customer satisfaction</Trans>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="credit-title fw-bold ls-m5p">7/24</div>
                            <div className="credit-description px-2 fw-500 ls-m5p">
                                <Trans>Our activity history</Trans>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="credit-title fw-bold ls-m5p">$€₺</div>
                            <div className="credit-description px-2 fw-500 ls-m5p">
                                <Trans>Services performed</Trans>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Credit
