import React from "react"
import Layout from "../components/template/Layout"
import Hero from "../components/about-us/Hero"
import Credit from "../components/about-us/Credit"

const AboutUs = () => {
    return (
        <Layout>
            <div className="bg-about-us">
                <Hero />
                <Credit />
                {/* <LearnMore /> */}
                {/* <NewsLetter /> */}
            </div>
        </Layout>
    )
}

export default AboutUs
