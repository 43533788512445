import React from "react"
import Layout from "../components/template/Layout"
import FeaturedPage from "../components/template/FeaturedPage"
import Loader from "../components/template/Loader"
import ErrorBox from "../components/template/ErrorBox"
import { Trans, useTranslation } from "react-i18next"
import { useFeedback } from "../providers/FeedbackProvider"
import FeedbackItem from "../components/feedback/FeedbackItem"
import { Icons } from "../utilities/Icons"

const Feedback = () => {
    // Methods
    const { t } = useTranslation()
    const { loading, questions, isSubmitLoading, onSubmitButtonClick, isDone } = useFeedback()

    // Methods

    // Render
    return (
        <Layout>
            <FeaturedPage>
                {loading && (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <Loader dark />
                    </div>
                )}
                {!loading && (
                    <div className="col-12">
                        <div className="row m-0">
                            {!questions && (
                                <div className="py-5 my-5">
                                    <ErrorBox
                                        error={t("Reservation not found")}
                                        className="text-center d-flex justify-content-center align-items-center"
                                    />
                                </div>
                            )}
                            {questions && !isDone && (
                                <div>
                                    <div className="fw-bold h2 mb-3">
                                        <Trans>How was your trip?!</Trans>
                                    </div>
                                    <div>
                                        {questions.map(item => {
                                            return <FeedbackItem item={item} key={item} />
                                        })}
                                    </div>
                                    <button
                                        onClick={onSubmitButtonClick}
                                        className="btn btn-secondary fw-500 py-2 px-5 mt-3"
                                        disabled={isSubmitLoading}
                                    >
                                        {isSubmitLoading ? <Loader /> : <Trans>Save</Trans>}
                                    </button>
                                </div>
                            )}
                            {questions && isDone && (
                                <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center py-5 py-sm-2">
                                    <div>{Icons.successTickCircle()}</div>
                                    <div className="h3 mt-4">
                                        <Trans>Feedback submitted successfully!</Trans>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </FeaturedPage>
        </Layout>
    )
}

export default Feedback
