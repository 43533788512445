import React, { useEffect } from "react"
import { useState } from "react"
import { Dropdown } from "react-bootstrap"
import PeopleSelectItem from "./PeopleSelectItem"
import { CaretDown } from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"

const PeopleSelect = ({
    shortFormClassName,
    longFormClassName,
    setPeople,
    people,
}) => {
    // States and Hooks
    const { t } = useTranslation()
    const [adultPeople, setAdultPeople] = useState(people.adultCount)
    const [childrenPeople, setChildrenPeople] = useState(people.childrenCount)
    const [infantPeople, setInfantPeople] = useState(people.infantCount)

    // Methods
    useEffect(
        () =>
            setPeople({
                adultCount: adultPeople,
                childrenCount: childrenPeople,
                infantCount: infantPeople,
            }),
        [adultPeople, childrenPeople, infantPeople]
    )

    // Render
    return (
        <Dropdown className="h-100">
            <Dropdown.Toggle
                variant="success"
                id="search-people"
                className="w-100 text-start rounded-0 bg-white text-dark fw-800 fs-14px p-3 pe-3"
            >
                <div className="d-flex justify-content-start align-items-center">
                    <span>
                        <Trans>People</Trans>:
                    </span>
                    <div className={`${longFormClassName} fs-12px ps-2`}>
                        {`(${adultPeople} ${t("adult")}${
                            adultPeople > 1 ? "s" : ""
                        }) ${
                            adultPeople && (childrenPeople || infantPeople)
                                ? "+"
                                : ""
                        } ${childrenPeople ? "(" : ""}${
                            childrenPeople ? childrenPeople : ""
                        } ${childrenPeople === 1 ? ` ${t("child")})` : ""}${
                            childrenPeople > 1 ? ` ${t("children")})` : ""
                        }
                ${adultPeople && childrenPeople && infantPeople ? "+" : ""} ${
                            infantPeople ? "(" : ""
                        }${infantPeople ? infantPeople : ""} ${
                            infantPeople === 1 ? `${t("infant")})` : ""
                        }${infantPeople > 1 ? `${t("infants")})` : ""}`}
                    </div>
                    <div className={`${shortFormClassName} fs-12px ps-2`}>
                        {`(${adultPeople + childrenPeople + infantPeople}) ${t(
                            "Total"
                        )}`}
                    </div>
                    <div className="ms-auto">
                        <img src={CaretDown} alt="caret-down" />
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-1 rounded-0 w-100">
                <PeopleSelectItem
                    title={t("Adult")}
                    value={adultPeople}
                    addFunc={() => {
                        setAdultPeople(adultPeople + 1)
                    }}
                    subtractFunc={() => {
                        adultPeople > 1 && setAdultPeople(adultPeople - 1)
                    }}
                    className="mb-2"
                    setState={setAdultPeople}
                />

                <PeopleSelectItem
                    title={t("Children")}
                    value={childrenPeople}
                    addFunc={() => {
                        setChildrenPeople(childrenPeople + 1)
                    }}
                    subtractFunc={() => {
                        childrenPeople && setChildrenPeople(childrenPeople - 1)
                    }}
                    className="mb-2"
                    setState={setChildrenPeople}
                />

                <PeopleSelectItem
                    title={t("Infant")}
                    value={infantPeople}
                    addFunc={() => {
                        setInfantPeople(infantPeople + 1)
                    }}
                    subtractFunc={() => {
                        infantPeople && setInfantPeople(infantPeople - 1)
                    }}
                    setState={setInfantPeople}
                />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default PeopleSelect
