import React from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import {
    AboutUsHeroBackgroundLarge,
    AboutUsHeroBackgroundSmall,
} from "../../utilities/ImageImports"
import { AppRoutes } from "../../utilities/AppRoutes"

const Hero = () => {
    return (
        <>
            <div className="bg-secondary bg-about-us-hero px-4 pt-4 px-lg-5 pt-lg-5">
                <div className="pb-custom">
                    <div className="row m-0 p-0 mb-5 justify-content-center">
                        <div className="d-none d-sm-block col-12 p-0">
                            <h6 className="pb-5 m-0">
                                <Link to={AppRoutes.home}>
                                    <span className="text-dark-gray">
                                        <Trans>Home</Trans>
                                    </span>
                                </Link>
                                <span className="text-dark-gray"> / </span>
                                <Link to="/about-us">
                                    <span className="text-white">
                                        <Trans>About Transferix</Trans>
                                    </span>
                                </Link>
                            </h6>
                        </div>
                        <div className="col-12 fw-bold h1 p-0 pt-5 pb-4 pb-sm-3 py-sm-4 text-center">
                            <span className="text-primary ls-m5p">
                                <Trans>About Transferix</Trans>
                            </span>
                        </div>
                        <div className="col-12 p-0 px-0 px-lg-5 pb-lg-4">
                            <p className="fw-lighter text-white about-text m-0 lh-200pr">
                                <div className="mb-2">
                                    <Trans>hero_l_1</Trans>
                                </div>
                                <div className="mb-2">
                                    <Trans>hero_l_2</Trans>
                                </div>
                                <div className="mb-2">
                                    <Trans>hero_l_3</Trans>
                                </div>
                                <div>
                                    <Trans>hero_l_4</Trans>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center about-us-hero">
                <img
                    src={AboutUsHeroBackgroundSmall}
                    alt="hero-background"
                    className="img-fluid d-lg-none"
                />
                <img
                    src={AboutUsHeroBackgroundLarge}
                    alt="hero-background"
                    className="img-fluid d-none d-lg-block"
                />
            </div>
        </>
    )
}

export default Hero
