import React from "react"
import { Link } from "react-router-dom"
import { ServicesHero, ServicesHeroBackground } from "../../utilities/ImageImports"
import { Trans } from "react-i18next"
import { AppRoutes } from "../../utilities/AppRoutes"

const Hero = () => {
    return (
        <div
            className="services-hero"
            style={{
                backgroundImage: `url(${ServicesHeroBackground})`,
            }}
        >
            <div className="row m-0 text-light py-sm-0">
                <div className="col-lg-8 col-12 py-5">
                    <div className="ps-lg-5 mb-0 mb-sm-3 mb-lg-0">
                        <div className="d-none d-sm-block col-12 ps-3 ps-lg-5">
                            <h6 className="m-0">
                                <Link to={AppRoutes.home}>
                                    <span className="text-dark-gray">
                                        <Trans>Home</Trans>
                                    </span>
                                </Link>
                                <span className="text-dark-gray"> / </span>
                                <Link to="/services">
                                    <span className="text-white">
                                        <Trans>Services</Trans>
                                    </span>
                                </Link>
                            </h6>
                        </div>
                    </div>
                    <div className="h-100 d-flex align-items-start justify-content-between justify-content-sm-center flex-column px-lg-5 px-3 mx-0 mx-lg-5">
                        <div className="fw-normal h1 pb-4 pb-sm-0 mb-0 mb-sm-4">
                            <div>
                                <Trans>For your transfer</Trans>
                            </div>
                            <div>
                                <span className="fw-bold">Transferix </span>
                                <Trans>is always with you</Trans>
                            </div>
                        </div>
                        <div className="fw-light h6 m-0 mt-sm-3 lh-200pr text-justify">
                            <Trans>
                                In every moment of your life, wherever you are in Turkey, we are
                                always here to provide your transportation needs under all
                                conditions and in the most comfortable way for you.
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                    <img src={ServicesHero} className="img-fluid" alt="Service hero" />
                </div>
            </div>
        </div>
    )
}

export default Hero
