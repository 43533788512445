import React from "react"
import Layout from "../components/template/Layout"
import OneWayTripSelect from "../components/search/OneWayTripSelect"
import FeaturedPage from "../components/template/FeaturedPage"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { AppRoutes } from "../utilities/AppRoutes"

const Search = () => {
    // States and Hooks

    // Methods

    // Render
    return (
        <Layout>
            <FeaturedPage>
                <div className="bg-white p-3">
                    <OneWayTripSelect />
                    <div className="row m-0">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 col-md-3 my-3">
                                <Link
                                    to={AppRoutes.completeInformation}
                                    className="btn btn-secondary fw-500 py-3 w-100"
                                >
                                    <Trans>Complete</Trans>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </FeaturedPage>
        </Layout>
    )
}

export default Search
