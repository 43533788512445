import React from "react"

const ContactItem = ({ icon, title, description, slim, children }) => {
    return (
        <>
            <div>
                <div
                    className={`d-flex justify-content-start align-items-center ${
                        slim ? "pb-0" : "pb-2"
                    }`}
                >
                    <img
                        src={icon}
                        alt="telephone"
                        className="img-fluid pe-2"
                    />
                    <p className="fs-16px fw-500 m-0 footer-text">{title}</p>
                </div>
                <p className="fs-16px fw-lighter footer-text m-0 text-break lh-200pr">
                    {description}
                </p>
            </div>
            {children}
        </>
    )
}

export default ContactItem
