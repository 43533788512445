import React from "react"
import { Accordion } from "react-bootstrap"
import InputMask from "react-input-mask"
import Loader from "../components/template/Loader"
import { useContactForm } from "../providers/ContactFormProvider"
import NewsLetter from "../components/home/NewsLetter"
import Layout from "../components/template/Layout"
import TextInput from "../components/complete-information/TextInput"
import ContactItem from "../components/template/ContactItem"
import { Link } from "react-router-dom"
import {
    User,
    Email,
    Share,
    FacebookAlt,
    TwitterAlt,
    InstagramAlt,
    MessageQuestionRect,
    GeoAlt,
    TelephoneAlt,
} from "../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"
import Alert from "../components/template/Alert"
import ErrorBox from "../components/template/ErrorBox"
import FeaturedPage from "../components/template/FeaturedPage"
import { AppRoutes } from "../utilities/AppRoutes"
import { CompanyPhoneNumber } from "../utilities/Config"

const Contact = () => {
    const { t } = useTranslation()

    const {
        contactFirstName,
        setContactFirstName,

        contactLastName,
        setContactLastName,

        contactEmail,
        setContactEmail,

        contactPhoneNumber,
        setContactPhoneNumber,

        contactNote,
        setContactNote,

        error,
        submitLoading,
        isContactSuccessAlertOpen,
        setIsContactSuccessAlertOpen,
        closeContactSuccessAlert,
        onSendButtonClick,
    } = useContactForm()

    return (
        <Layout>
            <FeaturedPage>
                <Alert show={isContactSuccessAlertOpen} onClose={closeContactSuccessAlert}>
                    Submitted Contact Information
                </Alert>
                <div className="p-0">
                    <div className="px-sm-4">
                        <div className="row m-0 p-0 mb-5 justify-content-center">
                            <div className="col-11 col-lg-12 p-0">
                                <h6 className="mb-5">
                                    <Link to={AppRoutes.home}>
                                        <span className="text-gray fw-normal">
                                            <Trans>Home</Trans>
                                        </span>
                                    </Link>
                                    <span> / </span>
                                    <Link to="/contact">
                                        <span className="text-secondary fw-normal">
                                            <Trans>Contact Us</Trans>
                                        </span>
                                    </Link>
                                </h6>
                            </div>
                            <div className="col-11 col-lg-5 p-0 pb-5 pb-lg-0 pe-lg-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="text-secondary lh-162pr fw-600">
                                            <Trans>TRANSFERIX CONTACT INFORMATION</Trans>
                                        </h2>
                                        <p className="mb-5">
                                            <Trans>
                                                Transferix Passenger Transportation and Tourism Inc.
                                            </Trans>
                                        </p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pb-4">
                                        <ContactItem
                                            icon={GeoAlt}
                                            title={t("Our Address")}
                                            description="Altınova Sinan Mahallesi Serik Caddesi No: 331 Kepez / ANTALYA"
                                            slim={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pb-4">
                                        <ContactItem
                                            icon={TelephoneAlt}
                                            title={t("Phone")}
                                            description={CompanyPhoneNumber}
                                            slim={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pb-4">
                                        <ContactItem
                                            icon={Email}
                                            title={t("Email")}
                                            description="info@cyprobus.com"
                                            slim={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pb-4">
                                        <ContactItem
                                            icon={MessageQuestionRect}
                                            title={t("Tax Office - No")}
                                            description={CompanyPhoneNumber}
                                            slim={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12">
                                        <ContactItem
                                            icon={Share}
                                            title={t("Social Networks")}
                                            description={null}
                                        >
                                            <div className="d-flex align-items-center">
                                                <a
                                                    href="https://www.facebook.com/CyproBus"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={FacebookAlt}
                                                        alt="facebook"
                                                        className="pe-3 pe-sm-4"
                                                    />
                                                </a>
                                                <a
                                                    href="https://www.instagram.com/cypro_bus"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={InstagramAlt}
                                                        alt="instagram"
                                                        className="pe-3 pe-sm-4"
                                                    />
                                                </a>
                                            </div>
                                        </ContactItem>
                                    </div>
                                </div>
                            </div>

                            <div className="col-11 col-lg-7 scroll-mt" id="contact-form">
                                <form>
                                    <div className="row p-3 mb-3 bg-white align-content-start">
                                        <ErrorBox error={error} />
                                        <div className="col-12 pe-0 col-md-6 p-0 pe-md-2 pb-3">
                                            <TextInput
                                                icon={User}
                                                placeholder={t("First Name")}
                                                value={contactFirstName}
                                                setValue={setContactFirstName}
                                            />
                                        </div>
                                        <div className="col-12 ps-0 col-md-6 p-0 ps-md-2 pb-3">
                                            <TextInput
                                                icon={User}
                                                placeholder={t("Last Name")}
                                                value={contactLastName}
                                                setValue={setContactLastName}
                                            />
                                        </div>
                                        <div className="col-12 pe-0 col-md-6 p-0 pe-md-2 pb-3">
                                            <TextInput
                                                icon={Email}
                                                placeholder={t("Email Address")}
                                                value={contactEmail}
                                                setValue={setContactEmail}
                                            />
                                        </div>
                                        <div className="col-12 ps-0 col-md-6 p-0 ps-md-2 pb-3">
                                            <div className="input-group">
                                                <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                                                    <img
                                                        src={TelephoneAlt}
                                                        alt="icon"
                                                        className="form-input-icon"
                                                    />
                                                </span>
                                                <InputMask
                                                    type="text"
                                                    className="form-control border-start-0 rounded-0 fw-normal form-input-text ps-0 p-3"
                                                    placeholder={t("Phone Number")}
                                                    mask="(999) 999 99 99"
                                                    value={contactPhoneNumber}
                                                    onChange={e => {
                                                        setContactPhoneNumber(
                                                            e.target.value.replace(/[^\d]/g, "")
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 p-0 pb-3 h-68">
                                            <div className="input-group h-100">
                                                <textarea
                                                    type="text"
                                                    className="form-control rounded-0 form-input-text p-3"
                                                    placeholder={`${t(
                                                        "The text of your message"
                                                    )} ...`}
                                                    value={contactNote}
                                                    onChange={e => {
                                                        setContactNote(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 p-0">
                                            <button
                                                type="submit"
                                                className="btn btn-secondary fw-light w-100 p-3 fw-600"
                                                onClick={onSendButtonClick}
                                                disabled={submitLoading}
                                            >
                                                {submitLoading ? <Loader /> : <Trans>Send</Trans>}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="py-5">
                        <div className="h2 fw-bold text-center">
                            <Trans>Frequently Asked Questions</Trans>
                        </div>
                        <Accordion className="col-lg-8 col-11 mx-auto pt-5">
                            {[1, 2, 3, 4, 5, 6, 7].map(item => {
                                return (
                                    <Accordion.Item key={item} eventKey={item} className="mb-3">
                                        <Accordion.Header>
                                            <Trans>{`faq_q_${item}`}</Trans>
                                        </Accordion.Header>
                                        <Accordion.Body className="lh-200pr">
                                            <Trans>{`faq_a_${item}`}</Trans>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                        </Accordion>
                    </div>
                    <NewsLetter />
                </div>
            </FeaturedPage>
        </Layout>
    )
}

export default Contact
