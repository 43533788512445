import React from "react"

const Credit = () => {
    return (
        <div className="row pt-5 px-0 m-0 justify-content-center">
            <div className="col-11 col-sm-8 col-lg-5 py-2 py-sm-5 text-center">
                <div className="h1 pt-2 pt-sm-5">Lorem Ipsum</div>
                <div className="h4 pb-2 pb-sm-5">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy
                </div>
            </div>
        </div>
    )
}

export default Credit
