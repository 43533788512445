import React from "react"
import "react-day-picker/dist/style.css"
import FormTitle from "./FormTitle"
import TextInput from "./TextInput"
import InputMask from "react-input-mask"
import { User, Email, Mobile, Calendar, Card, Lock, ArrowRight } from "../../utilities/ImageImports"
import { Trans, useTranslation } from "react-i18next"
import { useApp } from "../../providers/AppProvider"
import { useCompleteInformationForm } from "../../providers/complete-information/CompleteInformationFormProvider"
import ErrorBox from "../template/ErrorBox"
import HiddenOriginalPaymentForm from "./HiddenOriginalPaymentForm"

const CompleteInformationForm = () => {
    // States and Hooks
    const BaseApiUrl = process.env.REACT_APP_API_BASE_URL
    const { t } = useTranslation()
    const {
        error,
        paymentError,
        firstname,
        setFirstname,
        lastname,
        setLastname,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        note,
        setNote,

        cardNumber,
        setCardNumber,
        cardExpireDate,
        setCardExpireDate,
        cardCvv,
        setCardCvv,
    } = useCompleteInformationForm()

    const { currentLanguage } = useApp()

    return (
        <form>
            <div
                className="row px-4 px-md-0 px-lg-5 m-0 mb-5 mt-3 mt-sm-0 scroll-mt"
                id="complete-information-form"
            >
                <ErrorBox error={error} />
                <div className="col-12 col-md-8">
                    <div className="row p-3 mb-3 bg-white">
                        <FormTitle>
                            <Trans>Personal Details</Trans>
                        </FormTitle>
                        <div className="col-12 pe-0 col-sm-6 p-0 pe-sm-2 pb-3">
                            <TextInput
                                icon={User}
                                placeholder={t("First Name")}
                                value={firstname}
                                setValue={setFirstname}
                            />
                        </div>
                        <div className="col-12 ps-0 col-sm-6 p-0 ps-sm-2 pb-3">
                            <TextInput
                                icon={User}
                                placeholder={t("Last Name")}
                                value={lastname}
                                setValue={setLastname}
                            />
                        </div>
                        <div className="col-12 pe-0 col-sm-6 p-0 pe-sm-2 pb-3">
                            <TextInput
                                icon={Email}
                                placeholder={t("Email Address")}
                                value={email}
                                setValue={setEmail}
                            />
                        </div>
                        <div className="col-12 ps-0 col-sm-6 p-0 ps-sm-2 pb-3">
                            <div className="input-group">
                                <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                                    <img src={Mobile} alt="icon" className="form-input-icon" />
                                </span>
                                <InputMask
                                    type="text"
                                    className="form-control border-start-0 rounded-0 fw-normal form-input-text ps-0 p-3"
                                    placeholder={t("Gsm Number")}
                                    mask="(999) 999 99 99"
                                    value={phoneNumber}
                                    onChange={e => {
                                        setPhoneNumber(e.target.value.replace(/[^\d]/g, ""))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <div className="input-group">
                                <textarea
                                    type="text"
                                    rows={4}
                                    className="form-control rounded-0 form-input-text p-3"
                                    placeholder={t("Your Note")}
                                    value={note}
                                    onChange={e => {
                                        setNote(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4 ps-md-4">
                    <div className="row p-3 mb-3 bg-white">
                        <ErrorBox error={paymentError} />
                        <HiddenOriginalPaymentForm />
                        <FormTitle>
                            <Trans>Payment</Trans>
                        </FormTitle>
                        <div className="col-12 p-0 pb-3">
                            <div className="input-group">
                                <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                                    <img src={Card} alt="icon" className="form-input-icon" />
                                </span>
                                <InputMask
                                    type="text"
                                    className="form-control rounded-0 border-start-0 fw-normal form-input-text ps-0 p-3"
                                    placeholder={`${t("Credit/Debit Card Number")} :`}
                                    mask="9999 9999 9999 9999"
                                    value={cardNumber}
                                    onChange={e => setCardNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6 p-0 pe-2 pb-3">
                            <div className="input-group">
                                <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                                    <img src={Calendar} alt="icon" className="form-input-icon" />
                                </span>
                                <InputMask
                                    type="text"
                                    className="form-control rounded-0 border-start-0 fw-normal form-input-text ps-0 p-3"
                                    placeholder="MM/YY"
                                    mask="99/99"
                                    value={cardExpireDate}
                                    onChange={e => setCardExpireDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6 p-0 ps-2 pb-3">
                            <div className="input-group">
                                <span className="input-group-text border-end-0 rounded-0 bg-white justify-content-center p-2 px-3">
                                    <img src={Lock} alt="icon" className="form-input-icon" />
                                </span>
                                <InputMask
                                    type="text"
                                    className="form-control rounded-0 border-start-0 fw-normal form-input-text ps-0 p-3"
                                    placeholder="CVV"
                                    mask="999"
                                    value={cardCvv}
                                    onChange={e => setCardCvv(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="m-0 mb-4 p-0 fs-14px">
                            {currentLanguage === "en" && (
                                <div>
                                    By clicking pay button you agree with{" "}
                                    <a
                                        href={`${BaseApiUrl}/img/contracts/kvkk_${currentLanguage}.pdf`}
                                        target="_blank"
                                        className="text-decoration-underline"
                                    >
                                        <Trans>Terms of Service</Trans>
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href={`${BaseApiUrl}/img/contracts/sales_${currentLanguage}.pdf`}
                                        target="_blank"
                                        className="text-decoration-underline"
                                    >
                                        <Trans>Privacy Policy</Trans>
                                    </a>
                                </div>
                            )}
                            {currentLanguage === "tr" && (
                                <div>
                                    Öde'ye basarak{" "}
                                    <a
                                        href={`${BaseApiUrl}/img/contracts/kvkk_${currentLanguage}.pdf`}
                                        target="_blank"
                                        className="text-decoration-underline d-inline-block"
                                    >
                                        <Trans>Hizmet Şartları</Trans>
                                    </a>{" "}
                                    ve{" "}
                                    <a
                                        href={`${BaseApiUrl}/img/contracts/sales_${currentLanguage}.pdf`}
                                        target="_blank"
                                        className="text-decoration-underline"
                                    >
                                        <Trans>Gizlilik Politikasını</Trans>
                                    </a>{" "}
                                    kabul etmiş olursunuz
                                </div>
                            )}
                        </div>
                        <div className="row m-0 p-0">
                            <button
                                className="px-4 py-3 justify-content-between align-items-center btn btn-secondary"
                                type="submit"
                            >
                                <div className="d-flex flex-row flex-md-column flex-xl-row align-items-center justify-content-between">
                                    <h4 className="fw-700 text-primary price-digit m-0">
                                        {`${1234} ₺`}
                                    </h4>
                                    <div className="">
                                        <p className="fs-22px text-primary m-0">
                                            <Trans>Pay</Trans>
                                            <span>
                                                <img
                                                    src={ArrowRight}
                                                    alt="arrow-right"
                                                    className="small-icon"
                                                />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CompleteInformationForm
