export const Icons = {
    arrowRightTailed: () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4297 5.92993L20.4997 11.9999L14.4297 18.0699"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.5 12H20.33"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),

    error: () => (
        <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
        </svg>
    ),

    arrowRight: () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4302 5.93018L20.5002 12.0002L14.4302 18.0702"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.5 12H20.33"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    phone: () => (
        <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
        </svg>
    ),

    copy: () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 12.9V17.1C15.5 18.8007 15.1568 19.8611 14.5089 20.5089C13.8611 21.1568 12.8007 21.5 11.1 21.5H6.9C5.19931 21.5 4.13891 21.1568 3.49105 20.5089C2.8432 19.8611 2.5 18.8007 2.5 17.1V12.9C2.5 11.1993 2.8432 10.1389 3.49105 9.49105C4.13891 8.8432 5.19931 8.5 6.9 8.5H11.1C12.8007 8.5 13.8611 8.8432 14.5089 9.49105C15.1568 10.1389 15.5 11.1993 15.5 12.9Z"
                fill="#283C39"
                stroke="#283C39"
            />
            <path
                d="M12.8998 2.5H17.0998C18.8005 2.5 19.8609 2.8432 20.5087 3.49105C21.1566 4.13891 21.4998 5.19931 21.4998 6.9V11.1C21.4998 12.5962 21.2317 13.5965 20.726 14.2533C20.2325 14.8941 19.4461 15.292 18.2042 15.4335C17.9832 15.4586 17.7498 15.2736 17.7498 14.9781V12.9C17.7498 10.7323 17.2472 9.04033 16.1033 7.89645C14.9594 6.75257 13.2675 6.25 11.0998 6.25H9.02167C8.7262 6.25 8.54113 6.01659 8.5663 5.79559C8.70774 4.55368 9.1057 3.76727 9.74651 3.27381C10.4032 2.76809 11.4036 2.5 12.8998 2.5Z"
                fill="#283C39"
                stroke="#283C39"
            />
        </svg>
    ),

    successTickCircle: () => (
        <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48 8C25.96 8 8 25.96 8 48C8 70.04 25.96 88 48 88C70.04 88 88 70.04 88 48C88 25.96 70.04 8 48 8ZM67.12 38.8L44.44 61.48C43.88 62.04 43.12 62.36 42.32 62.36C41.52 62.36 40.76 62.04 40.2 61.48L28.88 50.16C27.72 49 27.72 47.08 28.88 45.92C30.04 44.76 31.96 44.76 33.12 45.92L42.32 55.12L62.88 34.56C64.04 33.4 65.96 33.4 67.12 34.56C68.28 35.72 68.28 37.6 67.12 38.8Z"
                fill="#00C9AF"
            />
        </svg>
    ),

    failCloseCircle: () => (
        <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48 8C25.96 8 8 25.96 8 48C8 70.04 25.96 88 48 88C70.04 88 88 70.04 88 48C88 25.96 70.04 8 48 8ZM61.44 57.2C62.6 58.36 62.6 60.28 61.44 61.44C60.84 62.04 60.08 62.32 59.32 62.32C58.56 62.32 57.8 62.04 57.2 61.44L48 52.24L38.8 61.44C38.2 62.04 37.44 62.32 36.68 62.32C35.92 62.32 35.16 62.04 34.56 61.44C33.4 60.28 33.4 58.36 34.56 57.2L43.76 48L34.56 38.8C33.4 37.64 33.4 35.72 34.56 34.56C35.72 33.4 37.64 33.4 38.8 34.56L48 43.76L57.2 34.56C58.36 33.4 60.28 33.4 61.44 34.56C62.6 35.72 62.6 37.64 61.44 38.8L52.24 48L61.44 57.2Z"
                fill="#C2554F"
            />
        </svg>
    ),

    successAlert: () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-check-circle"
            viewBox="0 0 16 16"
        >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
    ),

    dangerAlert: () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle"
            viewBox="0 0 16 16"
        >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    ),

    warningAlert: () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
        >
            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
        </svg>
    ),

    infoAlert: () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle"
            viewBox="0 0 16 16"
        >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>
    ),

    close: () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
        >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    ),

    returnArrow: () => (
        <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
            ></path>
        </svg>
    ),
    location: () => (
        <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
        </svg>
    ),
}
