import React, { useEffect } from "react"
import HeaderProvider from "../../providers/HeaderProvider"
import Header from "../home/Header"
import Footer from "../home/Footer"
import { useApp } from "../../providers/AppProvider"
import Loader from "./Loader"
const Layout = ({ children, fullWidth = false }) => {
    // States and Hooks
    const { loadingApp } = useApp()

    // UseEffects
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Render
    if (loadingApp)
        return (
            <div className="vh-100 d-flex align-items-center justify-content-center bg-white">
                <Loader dark />
            </div>
        )
    if (fullWidth)
        return (
            <div className="vh-100 d-flex flex-column">
                <HeaderProvider>
                    <Header />
                </HeaderProvider>
                <div className="flex-grow-1">{children}</div>
                <Footer />
            </div>
        )

    return (
        <>
            <HeaderProvider>
                <Header />
            </HeaderProvider>
            <div>{children}</div>
            <Footer />
        </>
    )
}
export default Layout
